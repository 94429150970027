import React, { useState, useRef } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import emailjs from '@emailjs/browser';

import ContactBG from '../Assets/bgImages/ContactBG.jpeg'

function Contact() {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_mobile: '',
    user_message: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.user_name.trim()) {
      newErrors.user_name = 'Name is required';
    }
    if (!formData.user_email.trim()) {
      newErrors.user_email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      newErrors.user_email = 'Email is invalid';
    }
    if (!formData.user_mobile.trim()) {
      newErrors.user_mobile = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(formData.user_mobile)) {
      newErrors.user_mobile = 'Mobile number should be 10 digits';
    }
    if (!formData.user_message.trim()) {
      newErrors.user_message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validate()) {
      emailjs
        .sendForm('service_x07swg6', 'template_qlfbpxb', form.current, {
          publicKey: 'kDbgkKmCSv-GnYO9R',
        })
        .then(
          () => {
            alert('Thank you for your message! We will get back to you shortly.');
            form.current.reset(); // Reset the form after submission
            setFormData({
              user_name: '',
              user_email: '',
              user_mobile: '',
              user_message: '',
            });
            setErrors({});
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    }
  };

  return (
    <>
      <Navbar />
      <div className='banner'
      style={{
        backgroundImage: `url(${ContactBG})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'local',         
        backgroundPosition: 'center',     
        backgroundRepeat: 'no-repeat',    
        height: 'auto',                  
        width: 'full', 
    }}>
        <div className='overlayStyle'>
          <h1>Get In Touch</h1>
          <p>~ Feel Free To Connect With Us ~</p>
        </div>
      </div>
      <div className='contactus'>
        <div className='contactus-left'>
          <div className='cont-adss'>
            <div className='cont-icon'>
              <i className='bi bi-geo-alt-fill'></i>
            </div>
            <p>IIT Roorkee, Roorkee, Uttarakhand<br />India - 247667</p>
          </div>
          <div className='cont-adss'>
            <div className='cont-icon'>
              <i className='bi bi-telephone-fill'></i>
            </div>
            <p>+91 7248186701 <br /> +91 1332 284857</p>
          </div>
          <div className='cont-adss'>
            <div className='cont-icon'>
              <i className='bi bi-envelope-fill'></i>
            </div>
            <p>kirti.mishra@me.iitr.ac.in<br />kirti.me@iitr.ac.in</p>
          </div>
          <div className='cont-adss'>
            <div className='cont-icon'>
              <i className='bi bi-geo-alt-fill'></i>
            </div>
            <p>Saharanpur, <br />Uttar Pradesh 247001</p>
          </div>
        </div>
        <div className='contactus-right'>
          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input
              placeholder='Name'
              name='user_name'
              value={formData.user_name}
              onChange={handleChange}
            />
            {errors.user_name && <p className="error">{errors.user_name}</p>}

            <label>Email</label>
            <input
              placeholder='Email'
              name='user_email'
              value={formData.user_email}
              onChange={handleChange}
            />
            {errors.user_email && <p className="error">{errors.user_email}</p>}

            <label>Mobile</label>
            <input
              placeholder='Mobile No.'
              name='user_mobile'
              value={formData.user_mobile}
              onChange={handleChange}
            />
            {errors.user_mobile && <p className="error">{errors.user_mobile}</p>}

            <label>Message</label>
            <input
              placeholder='Message'
              name='user_message'
              value={formData.user_message}
              onChange={handleChange}
            />
            {errors.user_message && <p className="error">{errors.user_message}</p>}

            <br />
            <br />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div className='map-div'>
        <iframe
          className='map'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d221359.18944098044!2d77.5678217373129!3d29.90066821888284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eb3650bfe37a7%3A0xa9d19b15af050467!2sIndian%20Institute%20Of%20Technology%20Roorkee!5e0!3m2!1sen!2sin!4v1720862959508!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
