import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import pm from '../Assets/modiji.webp'
import EmptyBG2 from '../Assets/bgImages/WWRBG4.jpeg'


function OurMotivation() {

    return (
        <>
            <Navbar />
            <div className='banner'
             style={{
                backgroundImage: `url(${EmptyBG2})`,
                backgroundSize: 'cover',
                    backgroundAttachment: 'local',         
                    backgroundPosition: 'center',     
                    backgroundRepeat: 'no-repeat',    
                    height: 'auto',                  
                    width: 'full', 
            }}
            >
                <div className='overlay'>
                    <h1>Our Motivation</h1>
                </div>
            </div>
            <div className='flex flex-col md:flex-row items-center justify-center'>
                <div className='motivation-right'>
                    <img src={pm} alt="Prime Minister" className="rounded-lg border-4 border-gray-300 shadow-lg" />
                </div>
                
                <p className='px-4 pb-4 text-left md:text-left'>
                    For achieving the set goals we are fortunate to be blessed with our honorable Prime Minister Mr. Narendra Modi.
                </p>
            </div>

            <Footer />
        </>
    )
}
export default OurMotivation;