import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import EmptyBG3 from '../Assets/bgImages/WWRBG3.jpeg'
function OurWow() {

    return (
        <>
            <Navbar />
            <div className='banner'
             style={{
                backgroundImage: `url(${EmptyBG3})`,
                backgroundSize: 'cover',
                    backgroundAttachment: 'local',         
                    backgroundPosition: 'center',     
                    backgroundRepeat: 'no-repeat',    
                    height: 'auto',                  
                    width: 'full', 
            }}>
                <div className='overlay'>
                    <h1>Our WoW</h1>
                </div>
            </div>
            <div className=" w-full py-4 flex justify-center"
           
            >
                <p className=" p-6 leading-relaxed font-bold">
                Our Way of Working is based upon maximum transparency and minimum communication gap. Due to the highly sensitive nature of work we always make sure that the team members are well trained in Good Laboratory Practices.
                </p>
            </div>
            <Footer />
        </>
    )
}
export default OurWow;