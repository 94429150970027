import React, { useState } from 'react';
import { MdOutlineClose } from "react-icons/md";

const EventCarousel = ({ imageSrc, title, description }) => {
  const [showPopup, setShowPopup] = useState(false);

  // Toggle popup visibility
  const handleImageClick = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="carousel-card relative flex flex-col items-center bg-white p-6 rounded-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-3">
      {/* Image with border and subtle shadow */}
      <img
        src={imageSrc}
        alt={title}
        className="w-full h-64 object-cover border-4 border-gray-100 shadow-md rounded-t-lg cursor-pointer"
        onClick={handleImageClick}
      />
      <h3 className="mt-4 text-xl font-bold text-gray-800">{title}</h3>

      {/* Popup container for the description */}
      {showPopup && (
        <div
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-10"
          onClick={() => setShowPopup(false)} // Close popup on click outside
        >
          <div
            className="bg-white p-6 rounded-lg max-w-sm text-center shadow-lg relative"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside popup
          >
            {/* Close button in the top-right corner */}
            <button
              onClick={() => setShowPopup(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none"
            >
              <MdOutlineClose className="text-lg" /> {/* Icon as close button */}
            </button>

            <h3 className="text-lg font-bold text-gray-800 mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCarousel;
