import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function BookItem({ id, title, details, toggleAccordion, isOpen }) {
  // Function to convert details string into HTML
  const createMarkup = () => {
    return { __html: details };
  };

  return (
    <div className='list-book' onClick={toggleAccordion}>
      <div className='acc-list'>
        <div>
          <div className='no'>
            <h3 style={{ cursor: 'pointer' }}>{id}</h3>
          </div>
        </div>
        <h2>{title}</h2>
      </div>
      {isOpen && details && (
        <div dangerouslySetInnerHTML={createMarkup()} />
      )}
    </div>
  );
}

function Books() {
  const [openBook, setOpenBook] = useState(null);

  const toggleAccordion = (id) => {
    setOpenBook(openBook === id ? null : id);
  };

  const booksData = [
    {
      id: 1,
      title: "Mishra, K.B., Experimental investigation and CFD simulation of organic peroxide pool fires (TBPB and TBPEH), BAM-Dissertation Series 63, 2010 ISBN-1613-4249, ISSN-1613-4249",
      
    },
    // Add more book items as necessary
  ];

  const bookChaptersData = [
    {
      id: 2,
      title: "Sharma, A., Mishra, K.B., 2023, A Numerical Case Study on Optimizing the Smoke Extraction Fans in a Model High-Rise Building Fire, Emerging Trends in Mechanical and Industrial Engineering, Part of the Lecture Notes in Mechanical Engineering book series (LNME), 405-417, Springer Singapore.",
      
    },
    {
      id: 1,
      title: "Mishra, K.B., Wehrstedt, K.D., 2018, Peroxy-fuels: Burning behavior and potential applications in combustion engines, Prospects of Alternative Transportation fuels, Springer Singapore.",
      
    },
    // Add more book chapter items as necessary
  ];

  return (
    <>
      <Navbar />
      <div className="banner banner-books">
        <div className="overlayStyle">
          <h1>Dissertations, Book Chapters, and Books</h1>
          <p>This page provides an extensive catalog of books and chapters related to fire dynamics and CFD simulations.</p>
        </div>
      </div>

      <div className="list-books">
        <h2>Dissertations</h2>
        {booksData.map((book) => (
          <BookItem
            key={book.id}
            id={book.id}
            title={book.title}
            details={book.details}
            toggleAccordion={() => toggleAccordion(book.id)}
            isOpen={openBook === book.id}
          />
        ))}

        <h2>Book Chapters</h2>
        {bookChaptersData.map((chapter) => (
          <BookItem
            key={chapter.id}
            id={chapter.id}
            title={chapter.title}
            details={chapter.details}
            toggleAccordion={() => toggleAccordion(chapter.id)}
            isOpen={openBook === chapter.id}
          />
        ))}
      </div>

      <Footer />
    </>
  );
}

export default Books;

