import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import WWDbg3 from '../../Assets/bgImages/WWDbg3.jpeg'
function ModelingAndSimulation() {

  return (
    <>
      <Navbar />
      <div className='banner'
        style={{
          backgroundImage: `url(${WWDbg3})`,
          backgroundSize: 'cover',        // Ensures image covers the whole area
          backgroundPosition: 'center',   // Keeps the focal point in the center
          backgroundRepeat: 'no-repeat',  // Prevents image repetition
        }}>
        <div className='overlay'>

          <h1>Modeling and Simulation</h1>

        </div>
      </div>
      <div className="w-full py-4 flex">
        <div className="flex flex-wrap gap-4 justify-center p-6 font-bold w-full">
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/modelingandsimulation/flammable-toxic-release', '_blank')}
          >
            Flammable and Toxic Release
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/modelingandsimulation/fire-explosion-scenarios', '_blank')}
          >
            Fire and Explosion Scenarios
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/modelingandsimulation/human-behaviour-evacuation', '_blank')}
          >
            Human Behaviour and Evacuation
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/modelingandsimulation/fire-suppression', '_blank')}
          >
            Fire Suppression
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/modelingandsimulation/combustion-burners-engines', '_blank')}
          >
            Combustion in Burners and Engines
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/modelingandsimulation/prediction-emission-components', '_blank')}
          >
            Prediction of Emission Components
          </button>
        </div>

      </div>

      <Footer />
    </>
  )
}
export default ModelingAndSimulation;