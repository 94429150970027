import React, { useState } from "react";
import logo from "../Assets/logo_M-removebg.png";
import { Link } from "react-router-dom";

function Navbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };
  return (
    <div className="Navbar sticky top-0 textshadow2" >
      <nav className="border-b sticky px-4">
        <img src={logo} alt="Logo" className="w-24 h-auto" />

        <ul className="text-base">
          <Link className="none" to="/">
            <li>Home</li>
          </Link>
          <li className="dropdown">
            <Link className="none">Who We Are</Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content ">
              <Link className="none" to="/ourorigin">
                Our Origin
              </Link>
              <Link className="none" to="/ourmandate">
                Our Mandate
              </Link>
              <Link className="none" to="/ourmotivation">
                Our Motivation
              </Link>
              <Link className="none" to="/ourwow">
                Our WoW
              </Link>
            </div>
          </li>
          <li className="dropdown">
            <Link className="none">What We Do</Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
              <Link className="none" to="/domainexpertise">
                Domain Expertise
              </Link>
              <Link className="none" to="/testingandmeasurement">
                Testing and Measurement</Link>
              <Link className="none" to="/modelingandsimulation">
                Modeling and Simulation
              </Link>
              <Link className="none" to="/thirdpartyreview">
                Third Party Review
              </Link>
              <Link className="none" to="/trainingprograms">
                Training Programs
              </Link>
            </div>
          </li>
          <li className="dropdown">
            <Link className="none">
              Products & Standards
            </Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
              <Link className="none" to="/testbenches">
                Test benches for the measurement of
              </Link>
              <Link className="none" to="/softwaresmodels">
                Softwares/Validated Models</Link>
              <Link className="none" to="/draftstandards">
                Draft Standards
              </Link>
              <Link className="none" to="/tailoredcourses">
                  Tailored Courses
                  </Link>
            </div>
          </li>

          <li className="dropdown">
            <Link className="none">
              Our Team
            </Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
              <Link className="none" to="/teams/groupLeader">
                Group Leader
              </Link>
              <Link className="none" to="/teams/currentworkingteam">
                Current Working Team
              </Link>
              <Link className="none" to="/teams/ouralumni">
                Our Alumni
              </Link>
              <Link className="none" to="/teams/ourstaff">
                Our Staff
              </Link>
            </div>
          </li>
          <li className="dropdown">
            <Link className="none">Projects</Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
              <Link className="none" to="/consultancy">
                Consultancy Project
              </Link>
              <Link className="none" to="/research">
              Research Project
              </Link>
            </div>
          </li>

          <li className="dropdown">
            <Link className="none">Publication</Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
              <Link className="none" to="/publication/patents">
                Patents
              </Link>
              <Link className="none" to="/publication/journals">
                Peer Reviewed Articles
              </Link>
              <Link className="none" to="/publication/conferences">
                Conference Proceeding
              </Link>
              <Link className="none" to="/publication/invitedtalks">
                Invited Talks
              </Link>
              <Link className="none" to="/publication/books">
                Book Chapters
              </Link>
              <Link className="none" to="/publication/presenrations">
                Poster & Presentation
              </Link>
            </div>
          </li>

          <Link className="none" to="/workwithus">
            <li>Work With Us</li>

          </Link>
          <Link className="none" to="/contact">
            <li>Contact Us</li>
          </Link>

        </ul>
        <i class="bi bi-list" onClick={toggleDivVisibility}></i>
      </nav>
      {isDivVisible && (
        <div className="toggleDiv-ops mt-6">
          <div className="toggleDiv">
            {/* Content of the div goes here */}
            <ul>
              <Link className="none" to="/">
                <li>Home</li>
              </Link>
              <li className="dropdown">
                <Link style={{ color: "white" }} className="none">Who We Are</Link>
                <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
                  <Link className="none" to="/ourorigin">
                    Our Origin
                  </Link>
                  <Link className="none" to="/ourmandate">
                    Our Mandate
                  </Link>
                  <Link className="none" to="/ourmotivation">
                    Our Motivation
                  </Link>
                  <Link className="none" to="/ourwow">
                    Our WoW
                  </Link>
                </div>
              </li>
              <li className="dropdown">
                <Link className="none text-white">What We Do</Link>
                <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
                  <Link className="none" to="/domainexpertise">
                    Domain Expertise
                  </Link>
                  <Link className="none" to="/testingandmeasurement">
                    Testing and Measurement</Link>
                  <Link className="none" to="/modelingandsimulation">
                    Modeling and Simulation
                  </Link>
                  <Link className="none" to="/thirdpartyreview">
                    Third Party Review
                  </Link>
                  <Link className="none" to="/trainingprograms">
                    Training Programs
                  </Link>
                </div>
              </li>
              <li className="dropdown">
                <Link className="none text-white">
                  Products & Standards
                </Link>
                <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
                  <Link className="none" to="/testbenches">
                    Test benches for the measurement of
                  </Link>
                  <Link className="none" to="/softwaresmodels">
                    Softwares/Validated Models</Link>
                  <Link className="none" to="/draftstandards">
                    Draft Standards
                  </Link>
                  <Link className="none" to="/tailoredcourses">
                  Tailored Courses
                  </Link>
                </div>
              </li>
              <li className="dropdown">
                <Link style={{ color: "white" }} className="none">
                  Projects
                </Link>
                <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
                  <Link className="none" to="/consultancy">
                    Consultancy Project
                  </Link>
                  <Link className="none" to="/research">
                  Research Project
                  </Link>
                </div>
              </li>


              <li className="dropdown">
                <Link style={{ color: "white" }} className="none">
                  Publication
                </Link>
                <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
                <Link className="none" to="/publication/patents">
                Patents
              </Link>
              <Link className="none" to="/publication/journals">
                Peer Reviewed Articles
              </Link>
              <Link className="none" to="/publication/conferences">
                Conference Proceeding
              </Link>
              <Link className="none" to="/publication/invitedtalks">
                Invited Talks
              </Link>
              <Link className="none" to="/publication/books">
                Book Chapters
              </Link>
              <Link className="none" to="/publication/presenrations">
                Poster & Presentation
              </Link>
                </div>
              </li>

              <li className="dropdown">
                <Link className="none text-white">
                  Our Team
                </Link>
                <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg rounded-md p-2 z-10 dropdown-content">
                  <Link className="none" to="/teams/groupLeader">
                    Group Leader
                  </Link>
                  <Link className="none" to="/teams/currentworkingteam">
                    Current Working Team
                  </Link>
                  <Link className="none" to="/teams/ouralumni">
                    Our Alumni
                  </Link>
                  <Link className="none" to="/teams/ourstaff">
                    Our Staff
                  </Link>
                </div>
              </li>
              <Link className="none" to="/workwithus">
                <li>Work With Us</li>
              </Link>
              <Link className="none" to="/contact">
                <li>Contact Us</li>
              </Link>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
