import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function ProcessAndPlantSafety() {
  return (
    <>
    <Navbar/>
    <div>ProcessAndPlantSafety</div>
    </>
  )
}

