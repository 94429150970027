import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function FireRetardancyComposites() {
  return (
    <>
    <Navbar/>
    <h1>fire-retardancy-composites</h1>
    </>
  )
}
