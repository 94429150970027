import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function StorageAndTransport() {
  return (
    <>
    <Navbar/>
    <div>storageAndTransportationofFlammables
    </div>
    </>
  )
}

