import React, { useState } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'

import staff2 from '../../Assets/01.jpg'
import staff1 from '../../Assets/WhatsApp Image 2024-10-13 at 11.05.49 PM.jpeg'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import arrow from '../../Assets/1549612.png'
import AluminiBG from '../../Assets/bgImages/AluminiBG.jpeg'

export default function OurStaff() {

    const togglemodal = (member) => {
        setSelectedMember(member);
        setmodal(!modal);
      };
    const [modal, setmodal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    const teamProjectStaff = [
        {
          id: 1,
          name: 'Poo Murugan',
          img: staff1,
          description: (
            <>
              <p>
                <span className="font-semibold">Poo murugan C</span> is from Madurai, Tamil Nadu, specializing in pool fire research through experimental and numerical simulations, with a focus on radiation effects.
              </p>
              <p className="mt-2">
              He is currently pursuing MTech from IIT Kanpur, where he initially focused on boundary layer diffusing flame research followed by modeling the chemical kinetics of reacting fuels to gain a deeper understanding of combustion processes and emissions. This research aims to optimize fuel efficiency and promote cleaner combustion for reducing environmental impacts.
              </p>
            </>
          )
        },
        {
          id: 2,
          name: 'Shivam Gupta',
          img: staff2,
          description: (
            <>
              <p>
                Shivam Gupta has worked as a Junior Research Fellow (JRF) under the project "CFD Simulation of Fire and Mist Interaction" from 2020-2021 for a period of 18 months.
                The project was sponsored by the Defence Research and Development Organization (DRDO) India. Large Eddy Simulation (LES) was performed under the project using Fire Dynamics Simulator (FDS). The project finds its applications in the field of Oil and Gas, Infrastructure, Defence etc. At present, he is doing PhD on multiphase flow in the Department of Chemical Engineering at IIT Roorkee.  His thesis addresses the FSI (Fluid Structure Interaction) based solution to Point Absorber Wave Energy Converter.

              </p>
            </>
          )
        }
      ];
    return (
        <>
            <Navbar />
            <h2 className='mt-4' style={{ textAlign: 'center' }}>Our Staff</h2>

      <div className='all-teams grid grid-cols-1 md:grid-cols-3 gap-6 relative'
        style={{
          backgroundImage: `url(${AluminiBG})`,   // Background image
          backgroundSize: 'cover',
          backgroundAttachment: 'local',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: 'auto',
          width: 'full',
        }}>
        <div className="absolute inset-0 bg-white opacity-50"></div>
        {teamProjectStaff.map((member) => (
          <div
            className='team-card cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg p-4 hover:shadow-xl transition-shadow duration-300'
            key={member.id}
          >
            <div className='w-full h-48 flex justify-center items-center bg-gray-100 rounded-lg'>
              <img
                src={member.img}
                alt={member.name}
                className='max-h-full max-w-full object-contain rounded-lg'
              />
            </div>
            <div className='team-name flex justify-between items-center w-full mt-4'>
              <p className='text-lg font-semibold text-gray-800'>{member.name}</p>
              <div onClick={() => togglemodal(member)}>
                <img className='arrow w-4 h-4' src={arrow} alt="arrow" />
              </div>
            </div>
          </div>
        ))}

        {selectedMember && (
          <Modal size='lg' isOpen={modal} toggle={() => togglemodal(null)}>
            <ModalHeader toggle={() => togglemodal(null)}>
              {selectedMember.name}
            </ModalHeader>
            <ModalBody>
              <div className='modal-div flex space-x-6'>
                <div className='modal-left w-1/3'>
                  <div className='w-full h-48 flex justify-center items-center bg-gray-100 rounded-lg'>
                    <img
                      src={selectedMember.img}
                      alt={selectedMember.name}
                      className='max-h-full max-w-full object-contain rounded-lg'
                    />
                  </div>
                </div>
                <div className='modal-right w-2/3'>
                  <p className='text-gray-700 leading-relaxed'>
                    {selectedMember.description}
                  </p>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
      <Footer />
        </>
    )
}
