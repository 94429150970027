import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom';

import Projectsbg2 from '../Assets/bgImages/SponsouredBG.jpeg'

function Sponsored() {
  const projects = [
    {
      id: 1,
      title: 'Model experiments to measure the spread of forest fires in a realistic configuration',
      agency: 'DST-SERB, New Delhi and IIT Roorkee',
      amount: '50.66',
      role: 'PI',
      startDate: '16/02/2022',
      endDate: '15/02/2025',
      status: 'Ongoing',
      url: 'https://www.trag.org.in/'

    },
    {
      id: 2,
      title: 'Development of flammability tester for commercial sanitizer',
      agency: 'Design Innovation Centre, IIT Roorkee',
      amount: '2',
      role: 'PI',
      startDate: '24/12/2020',
      endDate: '01/12/2021',
      status: 'Completed',
      url: 'https://www.trag.org.in/'

    },
    {
      id: 3,
      title: 'Simulation and Modelling of Fire-Mist Interaction',
      agency: 'DRDO, New Delhi',
      amount: '17.5',
      role: 'PI',
      startDate: '07/11/2019',
      endDate: '06/05/2021',
      status: 'Completed',
      url: 'https://www.trag.org.in/'

    },
    {
      id: 4,
      title: 'Fire detectors layout optimization for armoured vehicles',
      agency: 'DRDO, New Delhi',
      amount: '19.5',
      role: 'Co-PI',
      startDate: '01/04/2019',
      endDate: '31/09/2020',
      status: 'Completed',
      url: 'https://www.trag.org.in/'

    },
    {
      id: 5,
      title: 'Thermal radiation hazard from biofuel pool fires',
      agency: 'DST-SERB, New Delhi',
      amount: '39.96',
      role: 'PI',
      startDate: '09/09/2016',
      endDate: '09/09/2019',
      status: 'Completed',
      url: 'https://www.trag.org.in/'

    },
    {
      id: 6,
      title: 'Computational Investigations of industrial fire and explosion disasters',
      agency: 'IIT Roorkee',
      amount: '10',
      role: 'PI',
      startDate: '09/09/2015',
      endDate: '09/09/2018',
      status: 'Completed',
      url: 'https://www.trag.org.in/'

    },
    {
      id: 7,
      title: 'BLEVE and fireballs of peroxy-fuels',
      agency: 'Ministry of Economics and Energy, Germany',
      amount: '$200K*',
      role: 'Co-PI',
      startDate: '15/08/2015',
      endDate: '15/08/2018',
      status: 'Completed',
      url: 'https://www.trag.org.in/'

    }
  ];
  return (
    <>
      <Navbar />
      <div className='banner'
        style={{
          backgroundImage: `url(${Projectsbg2})`,
          backgroundSize: 'cover',        // Ensures image covers the whole area
          backgroundPosition: 'top',   // Keeps the focal point in the center
          backgroundRepeat: 'no-repeat',  // Prevents image repetition
        }}>
        <div className='overlay'>

          {/* This was a Sponsored Projects before, and now changing the tile without files.*/}
          <h1>Research Projects</h1>

        </div>
      </div>
      <div className="container mt-5">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Sponsoring Agency</th>
                <th scope="col">Amount (Lacs)</th>
                <th scope="col">PI or Co-PI</th>
                <th scope="col">Start date</th>
                <th scope="col">End date</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}>
                  <td>{project.title}</td>
                  <td>{project.agency}</td>
                  <td>{project.amount}</td>
                  <td>{project.role}</td>
                  <td>{project.startDate}</td>
                  <td>{project.endDate}</td>
                  <td>{project.status}</td>
                  <td>
                    <button
                      onClick={() => window.open(`/projects/${project.id}`, '_blank')}
                      className="btn btn-primary text-blue-500 underline"
                    >
                      View More
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <br />
      <br />
      <Footer />
    </>
  )
}

export default Sponsored