import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import WWDbg2 from '../../Assets/bgImages/WWDbg2.jpeg'
function TestingAndMeasurement() {

  return (
    <>
      <Navbar />
      <div className='banner'
        style={{
          backgroundImage: `url(${WWDbg2})`,
          backgroundSize: 'cover',        // Ensures image covers the whole area
          backgroundPosition: 'center',   // Keeps the focal point in the center
          backgroundRepeat: 'no-repeat',  // Prevents image repetition
        }}>
        <div className='overlay'>

          <h1>Testing and Measurement</h1>

        </div>
      </div>
      <div className="w-full py-4 flex">
        <div className="flex flex-wrap gap-4 justify-center p-6 font-bold w-full">
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/testingandmeasurement/safety-distances', '_blank')}
          >
            Safety Distances from Flammable Dispersion, Thermal Radiation, and Overpressure
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/testingandmeasurement/flammability-smoke-toxicity', '_blank')}
          >
            Flammability and Smoke Toxicity
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/testingandmeasurement/fire-retardancy', '_blank')}
          >
            Fire Retardancy
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/testingandmeasurement/fire-suppression', '_blank')}
          >
            Fire Suppression
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/testingandmeasurement/combustion-alternative-fuels', '_blank')}
          >
            Combustion of Alternative Fuels
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/testingandmeasurement/primary-secondary-emission-components', '_blank')}
          >
            Primary and Secondary Emission Components
          </button>
        </div>

      </div>

      <Footer />
    </>
  )
}
export default TestingAndMeasurement;