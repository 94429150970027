import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function FlammableToxicDispersion() {
  return (
    <>
    <Navbar></Navbar>
    <div>FlammableToxicDispersion</div>
    </>
  )
}
