import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function Journals() {
  const articles = [
     {
      id: 34,
      year: 2024,
      fullCitation: "Mishra, S., Mishra, K.B., 2024, Measurement of Primary and Secondary Emission from Combustion of Liquid Biofuels, Fuel. 381,1, 133256",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0016236124024050"
},
    {
      id: 33,
      year: 2024,
      fullCitation: "Sharma, A., Mishra, K.B., 2024, Performance Assessment of Stairwell Smoke Prevention Techniques in a Model High- Rise Building, Thermal Science Engineering Progress, 53, 102725.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S2451904924003433?via%3Dihub"
},
      {
      id: 32,
      year: 2024,
      fullCitation: "Upadhyay, R.K., Vishwakarma., P.K., Mishra, K.B, 2024, Failure analysis of a separator under various thermal loading: A numerical study, Journal of Loss Prevention in Process Industries, 91, 105399.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0950423024001578?via%3Dihub"    
},
     {
      id: 31,
      year: 2024,
      fullCitation: "Mishra, S., Mishra, K.B., 2024, Effect of Swirl Number on Structural and Emission Characteristics of Moderate Size Burner Flames, Process Safety and Environmental Protection, 189 , 1151-1160.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S095758202400822X?via%3Dihub"    
},
      {
      id: 30,
      year: 2024,
      fullCitation: "Vishwakarma, P. K., Mishra, K. B., & Aravind Kumar, A. (2024). Experimental Investigation on the Variation in Solid Fuel Fire Whirl Properties with Imposed and Fuel Rotation. Fire Technology, 1-40 ",
      details: "https://doi.org/10.1007/s10694-024-01627-4"    
},
    {
      id: 29,
      year: 2023,
      fullCitation: "P. K. Vishwakarma, Saumitra Mishra, K. B. Mishra, A. Aravind Kumar, The Effect of Fuel Rotation on Different Characteristics of Fire Whirls: An Experimental Analysis. Fire Technol 59, 2587–2619 (2023). ",
      details: "https://doi.org/10.1007/s10694-023-01418-3"    
},
    {
      id: 28,
      year: 2022,
      fullCitation: "P. K. Vishwakarma, K. B. Mishra, Influence of sequential fireballs on thermal safety distance estimations for organic peroxide drums. Volume 75, ISSN 0950-4230 (Journal of Loss Prevention in the Process Industries, Elsevier)",
      details: "https://doi.org/10.1016/j.jlp.2021.104683"    
    },
    {
      id: 27,
      year: 2021,
      fullCitation: "Sharma, A., Mishra, K.B., 2021, Experimental investigations on influence of chimney effect on fire response of facade materials, Journal of Building Engineering, 44, 103257.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S2352710221011153"    
    },
    {
      id: 26,
      year: 2021,
      fullCitation: "Mishra, S., Mishra, K.B., 2021, Numerical study of large-scale LNG vapour cloud explosion in an unconfined space, Process Safety and Environmental Protection, xx, 1-9 doi.org/10.1016/j.psep.2021.03.034.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0957582021001555?via%3Dihub"
    },
    {
      id: 25,
      year: 2021,
      fullCitation: "Mishra, S, Vishwakarma, P.K., Sharma, A., Mishra, K.B., 2021, Experimental investigation of CS2 (carbon disulphide) pool fires, Process Safety and Environmental Protection, 145, 207-210.",
      details: "https://www.researchgate.net/publication/343513919_Experimental_investigation_of_small-scale_CS2_carbon_disulphide_pool_fires"
    },
    {
      id: 24,
      year: 2020,
      fullCitation: "Sharma, A., Vishwakarma, P.K., Mishra, S., Mishra, K.B, 2020, Visible length and thermal radiation of WCO biodiesel and ethanol pool fires, Fuel, 280, 118396.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0016236120313922"
    },
    {
      id: 23,
      year: 2020,
      fullCitation: "Mishra, S, Chauhan, A., Mishra, K,B., 2020, Role of binary and ternary blends of WCO biodiesel on emission reduction in diesel engines, Fuel, 262, 116604.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0016236119319581?via%3Dihub"
    },
    {
      id: 22,
      year: 2019,
      fullCitation: "Sharma, A., Mishra, K.B, 2019, Experimental set-up to measure the maximum mass burning rate of storage tank fires, Process Safety and Environmental Protection, 131, 282-291.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0957582019309450"
    },
    {
      id: 21,
      year: 2019,
      fullCitation: "Blankenhagel, P., Wehrstedt, K.D., Mishra, K.B., Steinbach, J., 2019, The capability of commercial CFD code to predict organic peroxide fireball characteristics, Journal of Hazardous Materials, 365, 386-394.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0304389418310306"
    },
    {
      id: 20,
      year: 2018,
      fullCitation: "Blankenhagel, P., Wehrstedt, K.D., Mishra K.B., Steinbach, J., 2018, Thermal radiation assessment using infrared camera, Journal of Loss Prevention in Process Industries, 54, 246-253.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0950423018300123?via%3Dihub"
    },
    {
      id: 19,
      year: 2018,
      fullCitation: "Mishra, K.B., 2018, The influence of Volume Blockage Ratio on IOCL Jaipur explosion, Journal of Loss Prevention in Process Industries, 54, 196-205.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0950423018300317?via%3Dihub"
    },
    {
      id: 18,
      year: 2018,
      fullCitation: "Dasgotra, A., Varun Teja, GVVV, Sharma, A., Mishra, K.B., 2018, CFD modeling of large-scale flammable cloud dispersion using FLACS Journal of Loss Prevention in Process Industries, 56, 531-542.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0950423017301596?via%3Dihub"
    },
    {
      id: 17,
      year: 2017,
      fullCitation: "Blankenhagel, P., Wehrstedt, K.D., Mishra K.B., Xu, S., Steinbach, J., 2017, A new model for organic peroxide fireballs, Journal of Loss Prevention in Process Industries, 50, 237-242.",
      details: "https://doi.org/10.1016/j.jlp.2017.10.002"
    },
    {
      id: 16,
      year: 2016,
      fullCitation: "Mishra K.B., 2016, Multiple BLEVEs and fireballs: Case of a Russian road carrier accident, Journal of Loss Prevention in Process Industries, 41, 60-67.",
      details: "https://doi.org/10.1016/j.jlp.2016.03.003"
    },
    {
      id: 15,
      year: 2016,
      fullCitation: "Schmidt S., Mishra K. B., Wehrstedt, K. D., 2016, CFD based reproduction of Amuay refinery accident, Chemical Engineering Transactions, 48, 7-12.",
      details: "https://www.cetjournal.it/index.php/cet/article/view/CET1648002"
    },
    {
      id: 14,
      year: 2015,
      fullCitation: "Mishra, K.B., 2015, CFD model for large hazardous cloud spread predictions, with particular reference to Bhopal disaster, 2015, Atmospheric Environment, 117, 74-91.",
      details: "https://doi.org/10.1016/j.atmosenv.2015.06.038"
    },
    {
      id: 13,
      year: 2015,
      fullCitation: "Mishra, K.B., Wehrstedt, K.-D., 2015, Diffusive burning characteristics of blended peroxy-fuels: some experimental results, Fuel Processing Technology, 140, 324-330.",
      details: "https://doi.org/10.1016/j.fuproc.2015.06.014"
    },
    {
      id: 12,
      year: 2015,
      fullCitation: "Mishra, K.B., Wehrstedt, K.-D., 2015, Underground gas pipeline explosion and fire: CFD based assessment of foreseeability, Journal of Natural Gas Science and Engineering, 24, 526-542.",
      details: "https://doi.org/10.1016/j.jngse.2015.04.010"
    },
    {
      id: 11,
      year: 2015,
      fullCitation: "Mishra, K.B., Wehrstedt, K.-D., Krebs. H., 2015, BLEVE of hydrocarbons and peroxy-fuels: experiments and CFD simulation, Energy Procedia, 66, 149-152.",
      details: "https://doi.org/10.1016/j.egypro.2015.02.082"
    },
    {
      id: 10,
      year: 2014,
      fullCitation: "Mishra, K.B., Wehrstedt, K.-D., Krebs, H., 2014, Amuay refinery disaster: the aftermaths and challenges ahead, Fuel Processing Technology, 119, 198-203.",
      details: "https://doi.org/10.1016/j.fuproc.2013.10.025"
    },
    {
      id: 9,
      year: 2013,
      fullCitation: "Mishra K.B., Wehrstedt, K.D., 2013, Diffusive burning characteristics of peroxy-fuels, Fuel, 113, 158-164.",
      details: "https://doi.org/10.1016/j.fuel.2013.05.031"
    },
     {
      id: 8,
      year: 2013,
      fullCitation: "Schälike, S., Mishra, K.B., Wehrstedt, K.D.,  Malow, M., Berger, A., Schönbucher, A.,  2013, Mass burning rate of a large TBPB pool fire - experimental study and modeling, Chemical engineering transactions , 31, 853-858.",
      details: "https://www.researchgate.net/publication/259620711_Mass_Burning_Rate_of_a_Large_TBPB_Pool_Fire_-_Experimental_Study_and_Modeling"
},
     {
      id: 7,
      year: 2013,
      fullCitation: "Mishra, K.B., Wehrstedt, K.-D., 2013, Spill-over characteristics of di-tert-butyl peroxide: Two phase CFD investigations, Journal of Loss Prevention in Process Industries, 29, 186-197.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0950423014000370"    
},
     {
      id: 6,
      year: 2013,
      fullCitation: "Schälike, S., Mishra K.B., Wehrstedt, K.D., Schönbucher A., 2013, Limiting distances for flame merging of multiple n-heptane and di-tert-butyl peroxide pool fires,  Chemical Engineering Transactions, 32, ISBN 978-88-95608-23-5.",
      details: "https://www.cetjournal.it/index.php/cet/article/view/CET1332021"    
     },
    {
      id: 5,
      year: 2013,
      fullCitation: "Mishra, K.B., Wehrstedt, K.-D., Krebs, H., 2013, Lessons learned from recent fuel storage fires, Fuel Processing Technology, 107, 166-172.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0378382012002937"
    },
    {
      id: 4,
      year: 2013,
      fullCitation: "Mishra K.B., Wehrstedt, K.D., 2013, 'Deviceless' self-sustained oscillatory diffusive burning of TBPB and hybrid fuels, Fuel Processing Technology, 116, 339-345.",
      details: "https://doi.org/10.1016/j.fuproc.2013.07.010"
    },
    {
      id: 3,
      year: 2013,
      fullCitation: "Schälike, S., Chun, H., Mishra K.B., Wehrstedt, K.D., Schönbucher A., 2013, Mass burning rates of di-tert-butyl peroxide pool fires: experimental study and modeling, Combustion Science and Technology, 185, 408-419.",
      details: "https://www.researchgate.net/publication/263216774_Mass_Burning_Rates_of_Di-tert-butyl_Peroxide_Pool_Fires-Experimental_Study_and_Modeling"
    },
   {
      id: 2,
      year: 2011,
      fullCitation: "Mishra, K.B., Wehrstedt, K.-D., 2011, Decomposition effects on the mass burning rate of organic peroxide pool fires, Journal of Loss Prevention in Process Industries,  25, 1, 224-226.",
      details: "https://www.sciencedirect.com/science/article/abs/pii/S0950423011000957"
   },
     {
      id: 1,
      year: 2009,
      fullCitation: " Vela, I., Chun, H., Mishra K.B., Gawlowski, M., Sudhoff, P., Rudolph, M., Wehrstedt, K.D., Schönbucher, A., 2009, Prediction of the thermal radiation of large hydrocarbons and peroxide pool fires by CFD simulation, Forsch. Ingenieurwes, 73, 2, 87–97.",
      details: "https://link.springer.com/article/10.1007/s10010-009-0094-3"
     }
  ];
  const [openId, setOpenId] = useState(null);

  const handleToggle = (id) => {
    setOpenId(openId === id ? null : id);
  };

  return (
        <>
        <Navbar />
        <div className='banner banner4'>
          <div className='overlayStyle'>
            <h1>Reviewed Articles</h1>
            <p>This section provides a comprehensive list of various reviewed articles published in several national and international journals.</p>
          </div>
        </div>
  
        <div className='list-patents'>
          {articles.map(article => (
            <div key={article.id} className='list-patent'>
              <div className='acc-list'>
                <div>
                <div className='no' onClick={() => handleToggle(article.id)}>
                  <h3>{article.id}</h3>
                </div>
                </div>
                <h2 onClick={() => handleToggle(article.id)}>{article.fullCitation}</h2>
               
              </div>
              {openId === article.id && (
                  <div className='details'>
                    {article.details !== "DATA" ? (
                  <a href={article.details} target="_blank" rel="noopener noreferrer">
                    {article.details}
                  </a>
                ) : (
                  <p>No link available</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Journals;
                   

