import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function EmissionPredictions() {
  return (
    <>
    <Navbar></Navbar>
    <div>EmissionPredictions</div>
    </>
  )
}
