import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function SafetyRiskAssessmentReports() {
  return (
    <>
    <Navbar/>
    <div>safetyRiskAssessmentReports</div>
    </>
  )
}
