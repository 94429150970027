import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
// import About from "./Pages/About";
// import OurProjects from "./Pages/OurProjects";
// import Servies from "./Pages/Servies";
import Teams from "./Pages/Teams";
// import Expertise from "./Pages/Expertise";
// import Motivation from "./Pages/Motivation";
import Patents from "./Pages/Patents";
import Journals from "./Pages/Journals";
import Presenrations from "./Pages/Presenrations";
import Conferences from "./Pages/Conferences";
import Contact from "./Pages/Contact";
import Consultancy from "./Pages/Consultancy";
import 'bootstrap/dist/css/bootstrap.min.css';
import Sponsored from "./Pages/Sponsored";
import 'bootstrap/dist/css/bootstrap.css'
import Books from "./Pages/Books";
import Talks from "./Pages/Talks";
import WorkWithUs3 from "./Pages/WorkWithUs3";
import ProductsAndStandards from "./Pages/ProductsAndStandards";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurOrigin from "./Pages/OurOrigin";
import OurMandate from "./Pages/OurMandate";
import OurMotivation from "./Pages/OurMotivation";
import OurWow from "./Pages/OurWow";
import DomainExpertise from "./Pages/what we do/domainExpertise";
import TestingAndMeasurement from "./Pages/what we do/testingAndMeasurement";
import ModelingAndSimulation from "./Pages/what we do/modelingAndSimulation";
import ThirdPartyReview from "./Pages/what we do/thirdPartyReview";
import TestBenches from "./Pages/Products and Standards/testBenches";
import SoftwaresValidatedModels from "./Pages/Products and Standards/softwaresValidatedModels";
import DraftStandards from "./Pages/Products and Standards/draftStandards";
import FireExplosionSafety from "./Pages/what we do/domainExpertise/fireExplosionSafety";
import CfdModeling from "./Pages/what we do/domainExpertise/cfdModeling";
import RiskAssessment from "./Pages/what we do/domainExpertise/riskAssessment";
import AlternativeFuels from "./Pages/what we do/domainExpertise/alternativeFuels";
import SafetyDistances from "./Pages/what we do/testingAndMeasurement/safetyDistances";
import FlammabilitySmokeToxicity from "./Pages/what we do/testingAndMeasurement/flammabilitySmokeToxicity";
import FireRetardancy from "./Pages/what we do/testingAndMeasurement/fireRetardancy";
import FireSuppression from "./Pages/what we do/testingAndMeasurement/fireSuppression";
import CombustionAlternativeFuels from "./Pages/what we do/testingAndMeasurement/combustionAlternativeFuels";
import PrimarySecondaryEmissionComponents from "./Pages/what we do/testingAndMeasurement/primarySecondaryEmissionComponents";
import FlammableToxicRelease from "./Pages/what we do/modelingAndSimulation/flammableToxicRelease";
import FireExplosionScenarios from "./Pages/what we do/modelingAndSimulation/fireExplosionScenarios";
import HumanBehaviourEvacuation from "./Pages/what we do/modelingAndSimulation/humanBehaviourEvacuation";
import CombustionBurnersEngines from "./Pages/what we do/modelingAndSimulation/combustionBurnersEngines";
import PredictionEmissionComponents from "./Pages/what we do/modelingAndSimulation/predictionEmissionComponents";
import SafetyRiskAssessmentReports from "./Pages/what we do/thirdPartyReview/safetyRiskAssessmentReports";
import ClaimsVerificationValidation from "./Pages/what we do/thirdPartyReview/claimsVerificationValidation";
import VulnerabilityStudies from "./Pages/what we do/thirdPartyReview/vulnerabilityStudies";
import CurrentWorkingTeam from "./Pages/Teams/currentWorkingTeam";
import OurAlumni from "./Pages/Teams/ourAlumni";
import OurStaff from "./Pages/Teams/ourStaff";
import BurningRateFlammableLiquids from "./Pages/Products and Standards/testBenches/burningRateFlammableLiquids";
import ThermalRadiationJetFires from "./Pages/Products and Standards/testBenches/thermalRadiationJetFires";
import FireTurbulenceInteraction from "./Pages/Products and Standards/testBenches/fireTurbulenceInteraction";
import FireRetardancyComposites from "./Pages/Products and Standards/testBenches/fireRetardancyComposites";
import EmissionCombustionAlternativeFuels from "./Pages/Products and Standards/testBenches/emissionCombustionAlternativeFuels";
import FlammabilityAlcoholFormulations from "./Pages/Products and Standards/testBenches/flammabilityAlcoholFormulations";
import PoolFires from "./Pages/Products and Standards/softwaresValidatedModels/poolFires";
import BleveDamageAssessment from "./Pages/Products and Standards/softwaresValidatedModels/bleveDamageAssessment";
import FlammableToxicDispersion from "./Pages/Products and Standards/softwaresValidatedModels/flammableToxicDispersion";
import BuildingFireSmokeManagement from "./Pages/Products and Standards/softwaresValidatedModels/buildingFireSmokeManagement";
import AlternativeFuelCombustion from "./Pages/Products and Standards/softwaresValidatedModels/alternativeFuelCombustion";
import EmissionPredictions from "./Pages/Products and Standards/softwaresValidatedModels/emissionPredictions";
import StorageAndTransport from "./Pages/Products and Standards/draftStandards/storageAndTransport";
import SponsoredProjectDetails from "./Pages/SponsoredProjectDetails";
import ConsultancyProjectDetails from "./Pages/ConsultancyProjectDetails";
import TrainingPrograms from "./Pages/what we do/trainingPrograms";
import TailoredCourses from "./Pages/Products and Standards/tailoredCourses";
import ProjectBasedTraining from "./Pages/what we do/trainingPrograms/projectBasedTraining";
import LongTermTraining from "./Pages/what we do/trainingPrograms/longTermTraining";
import ShortTermTraining from "./Pages/what we do/trainingPrograms/shortTermTraining";
import IntroductoryTraining from "./Pages/what we do/trainingPrograms/introductoryTraining";
import ExplosionModelling from "./Pages/Products and Standards/tailoredTraining/ExplosionModelling";
import Combustion from "./Pages/Products and Standards/tailoredTraining/Combustion";
import ProcessAndPlantSafety from "./Pages/Products and Standards/tailoredTraining/ProcessAndPlantSafety";
import FireMeasurements from "./Pages/Products and Standards/tailoredTraining/FireMeasurements";
import FireModelling from "./Pages/Products and Standards/tailoredTraining/FireModelling";





function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/project" element={<OurProjects />} /> */}
        {/* <Route path="/Servies" element={<Servies />} /> */}
        <Route path="/teams/groupLeader" element={<Teams />} />
        {/* <Route path="/expertise" element={<Expertise />} /> */}
        {/* <Route path="/motivation" element={<Motivation />} /> */}
        <Route path="/publication/patents" element={<Patents />} />
        <Route path="/publication/journals" element={<Journals />} />
        <Route path="/publication/conferences" element={<Conferences />} />
        <Route path="/publication/presenrations" element={<Presenrations />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/consultancy" element={<Consultancy />} />
        <Route path="/research" element={<Sponsored />} />
        <Route path="/workwithus" element={<WorkWithUs3/>} />
        <Route path="/publication/books" element={<Books />} />
        <Route path="/talks" element={<Talks />} />
        <Route path="/ProductsAndStandards" element={<ProductsAndStandards />} />
        <Route path="/ourorigin" element={<OurOrigin />} />
        <Route path="/ourmandate" element={<OurMandate />} />
        <Route path="/ourmotivation" element={<OurMotivation />} />
        <Route path="/ourwow" element={<OurWow/>} />
        <Route path="/publication/invitedtalks" element={<Talks/>} />
        <Route path="/domainexpertise" element={<DomainExpertise/>} />
        <Route path="/testingandmeasurement" element={<TestingAndMeasurement/>} />
        <Route path="/modelingandsimulation" element={<ModelingAndSimulation/>} />
        <Route path="/thirdpartyreview" element={<ThirdPartyReview/>} />
        <Route path="/trainingprograms" element={<TrainingPrograms/>} />
        <Route path="/testbenches" element={<TestBenches/>} />
        <Route path="/softwaresmodels" element={<SoftwaresValidatedModels/>} />
        <Route path="/tailoredcourses" element={<TailoredCourses/>} />

        <Route path="/draftstandards" element={<DraftStandards/>} />
        <Route path="/domainexpertise/fire-explosion-safety" element={<FireExplosionSafety/>} />
        <Route path="/domainexpertise/cfd-modeling" element={<CfdModeling/>} />
        <Route path="/domainexpertise/risk-assessment" element={<RiskAssessment/>} />
        <Route path="/domainexpertise/alternative-fuels" element={<AlternativeFuels/>} />
        <Route path="/testingandmeasurement/safety-distances" element={<SafetyDistances/>} />
        <Route path="/testingandmeasurement/flammability-smoke-toxicity" element={<FlammabilitySmokeToxicity/>} />
        <Route path="/testingandmeasurement/fire-retardancy" element={<FireRetardancy/>} />
        <Route path="/testingandmeasurement/fire-suppression" element={<FireSuppression/>} />
        <Route path="/testingandmeasurement/combustion-alternative-fuels" element={<CombustionAlternativeFuels/>} />
        <Route path="/testingandmeasurement/primary-secondary-emission-components" element={<PrimarySecondaryEmissionComponents/>} />
        <Route path="/modelingandsimulation/flammable-toxic-release" element={<FlammableToxicRelease/>} />
        <Route path="/modelingandsimulation/fire-explosion-scenarios" element={<FireExplosionScenarios/>} />

        <Route path="/modelingandsimulation/human-behaviour-evacuation" element={<HumanBehaviourEvacuation/>} />
        <Route path="/modelingandsimulation/fire-suppression" element={<FireSuppression/>} />
        <Route path="/modelingandsimulation/combustion-burners-engines" element={<CombustionBurnersEngines/>} />
        <Route path="/modelingandsimulation/prediction-emission-components" element={<PredictionEmissionComponents/>} />
        
        <Route path="thirdpartyreview/safety-risk-assessment-reports" element={<SafetyRiskAssessmentReports/>} />
        <Route path="thirdpartyreview/claims-verification-validation" element={<ClaimsVerificationValidation/>} />
        <Route path="thirdpartyreview/vulnerability-studies" element={<VulnerabilityStudies/>} />
        <Route path="/teams/currentworkingteam" element={<CurrentWorkingTeam/>} />
        <Route path="/teams/ouralumni" element={<OurAlumni/>} />
        <Route path="/teams/ourstaff" element={<OurStaff/>} />


        <Route path="/testbenches/burning-rate-flammable-liquids" element={<BurningRateFlammableLiquids/>} />
        <Route path="/testbenches/thermal-radiation-jet-fires" element={<ThermalRadiationJetFires/>} />
        <Route path="/testbenches/fire-turbulence-interaction" element={<FireTurbulenceInteraction/>} />
        <Route path="/testbenches/fire-retardancy-composites" element={<FireRetardancyComposites/>} />
        <Route path="/testbenches/emission-combustion-alternative-fuels" element={<EmissionCombustionAlternativeFuels/>} />
        <Route path="/testbenches/flammability-alcohol-formulations" element={<FlammabilityAlcoholFormulations/>} />


        <Route path="/softwaresmodels/pool-fires" element={<PoolFires/>} />
        <Route path="/softwaresmodels/bleve-damage-assessment" element={<BleveDamageAssessment/>} />
        <Route path="/softwaresmodels/flammable-toxic-dispersion" element={<FlammableToxicDispersion/>} />
        <Route path="/softwaresmodels/building-fire-smoke-management" element={<BuildingFireSmokeManagement/>} />
        <Route path="/softwaresmodels/human-behaviour-evacuation" element={<HumanBehaviourEvacuation/>} />
        <Route path="/softwaresmodels/alternative-fuel-combustion" element={<AlternativeFuelCombustion/>} />
        <Route path="/softwaresmodels/emission-predictions" element={<EmissionPredictions/>} />


        <Route path="/draftstandards/storageAndTransport" element={<StorageAndTransport/>} />

        <Route path="/projects/:id" element={<SponsoredProjectDetails />} />
        <Route path="/consultancy/:id" element={<ConsultancyProjectDetails />} />

        <Route path="/trainingprograms/project-based-training" element={<ProjectBasedTraining />} />
        <Route path="/trainingprograms/long-term-training" element={<LongTermTraining />} />
        <Route path="/trainingprograms/short-term-training" element={<ShortTermTraining />} />
        <Route path="/trainingprograms/short-term-training" element={<ShortTermTraining />} />
        <Route path="/trainingprograms/introductory-training" element={<IntroductoryTraining />} />


        <Route path="/tailoredtraining/Risk-assessment" element={<RiskAssessment />} />
        <Route path="/tailoredtraining/explosion-modelling" element={<ExplosionModelling />} />
        <Route path="/tailoredtraining/combustion" element={<Combustion />} />
        <Route path="/tailoredtraining/process-and-plant-safety" element={<ProcessAndPlantSafety />} />
        <Route path="/tailoredtraining/fire-measurements-and-modelling" element={<FireMeasurements />} />
        <Route path="/tailoredtraining/fire-modelling" element={<FireModelling />} />

      </Routes>
    </Router>
  );
}

export default App;
