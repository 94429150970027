import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function SafetyDistances() {
  return (
    <>
    <Navbar/>
    <div>SafetyDistances</div>
    </>
  )
}
