import React, { useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Conferences() {
  const [openIndex, setOpenIndex] = useState(null);
  const conferenceData = [
    {
      number: 46,
      event: "International Conference on Fire Safety Engineering Research and Practice (iCFSERP-2024), Western Sydney University, Parramatta, Sydney, Australia, 24-27 November 2024.",
      details: 'Sharma, A., Murugan, C, Mishra, K.B., 2024, Fire Safety in Modern Urban Infrastructures: A 3D CFD Approach to Complex Fires using Fire Dynamics Simulator (FDS).'
    },
    {
      number: 45,
      event: "International Conference on Fire Safety Engineering Research and Practice (iCFSERP-2024), Western Sydney University, Parramatta, Sydney, Australia, 24-27 November 2024",
      details: 'Vishwakarma, P.K., Mishra, K.B., 2024, Numerical modelling of medium-scale liquid fuel fire whirl.'
    },
    {
      number: 44,
      event: "Asian Congress on Gas Turbines (ACGT-2024), IIT Kanpur, India, 21-23 August 2024",
      details: 'Jain, S., Vishwakarma P.K., Upadhyay, R.K., Mishra, K.B., 2024, Numerical investigation of CH4 - H2 – Air Combustion in a Jet Engine Combustor'
    },
    {
      number: 43,
      event: "ICAFEE 2023 International Conference series on Alternative Fuels, Energy and the Environment: Future and Challenges (ICAFEE), 6-8 October 2023, Erciyes University Turkey.",
      details: 'Mishra, S., and Mishra, K. B., 2023, Effect of Swirl Number on Structural and Emission Characteristics of Moderate Size Burner Flames'
    },
    {
      number: 42,
      event: "5th ISEES International Conference on 'Sustainable Energy and Environmental Challenges' (V-SEEC), 19th – 21st December 2020, IIT Kanpur, India",
      details: 'Mishra, S., Mishra, K.B., Emission measurements of alternative fuel blends (biodiesel, methanol, ethanol, and DTBP) in diesel engines'
    },
     {
      number: 41,
      event: "Interntional Conference on Sustainnable Energy and Environmental Challenges (SEEC VII ), 16-18 Dec 2022 IIT BHU, Varanasi.",
      details: 'Upadhyay, R.K. Vishwakarma, P.K., Mishra, K.B., 2022, Numerical investigation on thermal response of a separator'
    },
     {
      number: 40,
      event: "National Conference on IC Engines and Combustion (NCICEC-2022), 5-7 Nov. 2022, VIT, Vellore.",
      details: 'Yadav, B.M., Mishra, S., Mishra, K,B., 2022 Effect of engine size on the emission and performance characteristics of Diesel, Biodiesel, ethanol and DTBP'
    },
    {
      number: 39,
      event: "National Conference on Safety, Cochin University of Science and Technology, 12-15 December 2019, Cochin",
      details: 'Sharma, A., Mishra, K.B., Performance-based fire safety design of modern infrastructure'
    },
    {
      number: 38,
      event: "2nd Oil and Gas HSE Conclave, 11-12 December 2019, New Delhi",
      details: "Mishra, K.B., Modelling-based risk assessment of gas dispersion and explosion in real configuration"
    },
    {
      number: 37,
      event: "14th International Fire India 2019 Conference, 19-21 Sep, 2019, New Delhi, India",
      details: "Sharma, A., Mishra, K.B., Modelling of High-rise façade fire"
    },
    {
      number: 36,
      event: "International Seminar of Fire and Explosion Hazards, Sat. Petersberg, Russia, April 2019",
      details: "Sharma, A., Mishra, K.B., Numerical Studies on Different Stairwell Smoke Extraction Techniques in a High-Rise Building"
    },
    {
      number: 35,
      event: "Proceedings of Fire India -2018, HiTex, Hyderabad, 22-23 Nov. 2018",
      details: "Sharma, A., Murugan, P., Mishra, K.B., CFD modeling of complex fires and evacuation in modern urban infrastructure"
    },
    {
      number: 34,
      event: "Proceedings of Aircraft Rescue and Firefighting conference, Hyderabad, 26-28 Feb. 2019",
      details: "Pomurugan, C., Mishra, K.B., Modeling of fire and evacuation scenario in passenger aircraft"
    },
    {
      number: 33,
      event: "Proceedings of 2nd European SFPE (Society of Fire Protection Engineers) Conference, 5-6 February 2018, Rotterdam, The Netherlands",
      details: "Mishra, K.B., Effect of Heating Rate on Burning Behaviour of Organic Peroxide in steel drums and IBC’s"
    },
    {
      number: 32,
      event: "2nd Intl. Conference on Sustainable Energy and Environmental Challenges, SEE-2018, IISc Bangalore, 31 Dec.-3 Jan. 2018",
      details: "Chauhan, A., Mishra, K.B., 2017, Experimental investgation of performance of  biodiesel, ethanol and peroxide blends in 60 W diesel engine."
    },
    {
      number: 31,
      event: "12th Intl. Symposium on Fire Safety Science, Lund University, Sweden, 12-17 June 2017",
      details: "Sharma, A., Mishra, K.B., High-rise building fires: Effective ventilation and optimum fan location"
    },
    {
      number: 30,
      event: "8th European Combustion Meeting, Dubrovnik, Croatia, 18-21 April 2017",
      details: "Sharma, A., Mishra, K.B., LES of large ethanol pool fires"
    },
    {
      number: 29,
      event: "5th Fire and Explosion Days, University of Magdeburg, Germany, 23-24 March 2017",
      details: "Blankenhagel, P., Mishra, K.B., Wehrstedt, K.D., Steinbach, J., Fireballs as a possible hazard scenario of organic peroxide in steel drums"
    },
    {
      number: 28,
      event: "19th International Seminar New Trends in Research of Energetic Materials, Czech Republic, June 2016",
      details: "Blankenhagel, P., , Mishra K.B., Wehrstedt, K.D. , Steinbach, J., Thermal radiation impact of DTBP fireballs"
    },
    {
      number: 27,
      event: "12. Fachtagung Arbeits-, Anlagen- und Umweltsicherheit (12 Symposium on Work, Plant and Environment Safety), Köthen, Germany",
      details: "Knechtel, S., Schmidt, S., Mishra, K.B., Wehrstedt, K.-D., 2015, Modelling of major fire scenarios of LH2 using CFD"
    },
    {
      number: 26,
      event: "4th Magdeburger Fire and Explosion Day, Magdeburg, Germany 2016",
      details: "Schmidt S., Mishra K. B., Wehrstedt, K. D., 2015, CFD predictions of thermal safety distances from jet fires of peroxy-fuels"
    },
    {
      number: 25,
      event: "12th International Conference on Combustion and Energy Utilisation, Lancaster University, UK",
      details: "Mishra, K.B., Wehrstedt, K.-D., Krebs, H., 2014, Investigations of BLEVE and fire balls of hydrocarbons and peroxy-fuels: experiments and CFD simulation, Paper No. 28"
    },
    {
      number: 24,
      event: "4th Magdeburg Day of Fire and Explosion Safety, Magdeburg, Germany 2014",
      details: "Schmidt S., Mishra, K .B., Wehrstedt, K.D.,  2014, CFD prediction of safety distancees from jet fires of peroxy-fuels"
    },
    {
      number: 23,
      event: "11. Fachtagung 'Anlagen-, Arbeits- und Umweltsicherheit, Köthen, Germany, 7-8 Nov. 2013",
      details: "Schälike, S., Mishra, K.B., Wehrstedt, K.D.,  Malow, M., Schönbucher, A., Kritische thermische Abstände wechselwirkender KW- und Peroxid-Poolfeuer"
    },
    {
      number: 22,
      event: "14th EFCE symposium on loss prevention and safety promotion in the process industries, Chemical engineering transactions (CEt), 2013, 853-858, ISBN: 978-88-95608-22-8",
      details: "Schälike, S., Mishra, K.B., Wehrstedt, K.D., Malow, M., Berger, A., Schönbucher, A., Mass burning rate of a large TBPB pool fire - experimental study and modeling"
    },
    {
      number: 21,
      event: "ICheaP-11 - 11th International conference on chemical & process engineering, 2013, 32, 121-126, ISBN: 978-88-95608-23-5",
      details: "Schälike, S., Mishra, K.B., Wehrstedt, K.D., Schönbucher, A.,  Limiting distances for flame merging of multiple n-heptane and di-tert-butyl peroxide pool fires"
    },
    {
      number: 20,
      event: "3. Magdeburger Brand- und Explosionsschutztag / vfdb-Workshop, 2013, Magdeburg, Germany, ISBN 978-3-00-041601-9",
      details: "Schälike, S., Mishra, K.B., Wehrstedt, K.D., Schönbucher, A., Experimentelle Untersuchung der Massenabbrandraten multipler n-Heptan und Di-tert-butylperoxid Poolfeuer"
    },
    {
      number: 19,
      event: "11th Engine Combustion Processes, 13- 15 March, Ludwigsburg, Germany",
      details: "Mishra, K.B., Wehrstedt, K.-D., 2013, Peroxy-fuels: Potential application in engine combustion"
    },
    {
      number: 18,
      event: "4th International Congress on Computational Mechanics and Simulation (ICCMS 2012), Paper No. 413, 9-12 December, 2012, IIT Hyderabad, India",
      details: "Mishra, K.B., Wehrstedt, K.-D., 2012, Dispersion characteristics of flammable liquids: A CFD study"
    },
    {
      number: 17,
      event: "Thirty Ninth National Conference on Fluid Mechanics and Fluid Power (NCFMFP-12) Paper No. 209 , 13-15 December, 2012, SVNIT Surat, India, ISBN: 978-81-925-494-0-8",
      details: "Mishra, K.B., Wehrstedt, K.-D., 2012, CFD simulation of hybrid fuel combustion",
    },
    {
      number: 16,
      event: "Combustion research and application (COMBURA -2012), 3-4 October 2012, Maastricht, The Netherlands",
      details: "Mishra, K.B., Wehrstedt, K.-D., 2012, Applications of peroxy-fuels in vehicle propulsion"
    },
    {
      number: 15,
      event: "11th International conference on combustion and energy utilization (ICCEU-2012), 9-13 May 2012, Coimbra, Portugal",
      details: "Mishra, K.B., Wehrstedt, K.-D., Krebs, H., 2012, Lessons learned from recent fuel storage fires"
    },
    {
      number: 14,
      event: "11th Conference on energy for a clean environment, 5-8 August, 2011, Lisbon, Portugal",
      details: "Mishra, K. B., Wehrstedt, K.-D., 2011 An efficient and clean fuel for high temperature process industries"
    },
    {
      number: 13,
      event: "VDI-Berichte - 25. Deutscher Flammentag - Verbrennung und Feuerung (VDI Report-25 German Flame Day- Combustion and Furnaces), 14-15 September 2011, 307-312, KIT Karlsruhe, Germany, ISBN- 978-3-18-092119-8",
      details: "Mishra, K.B., Wehrstedt, K.-D., 2011, Burner for peroxy-fuels (PEROXY-BAM Burner)"
    },
    {
      number: 12,
      event: "Proceedings of 38th National conference on fluid mechanics and fluid power (NCFMFP-11), Paper No. HT-07, 15-17 December, 2011, MANIT, Bhopal, India",
      details: "Mishra, K.B., Wehrstedt, K.-D., 2011, CFD simulation to predict the performance of a peroxy-fuel burner"
    },
    {
      number: 11,
      event: "ERCOFTAC conference - Highly resolved experimental and numerical diagnostics for turbulent combustion -HRTC-1, 25-26 May 2011, Rouen, France",
      details: "Mishra, K.B., Wehrstedt, K.-D., 2011, Combustion of peroxy-fuels"
    },
    {
      number: 10,
      event: "Proceedings of 5th European combustion meeting (ECM- 2011), 28 June-2 July 2011, Cardiff, UK",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2011, Interpretations of temperature measurements in organic peroxide pool fires"
    },
    {
      number: 9,
      event: "8th Asia-Pacific conference on combustion, The combustion institute - Indian Section (CIIS), 697-702, 10-13 December 2010, Hyderabad, India",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010, Pool and tank fires - Are we really prepared?"
    },
    {
      number: 8,
      event: "Proceedings of Combustion research and application (COMBURA -2010), 12-13 October, 2010, Maastricht, The Netherlands",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010, Safety aspects of organic peroxide pool fires"
    },
    {
      number: 7,
      event: "Proceedings of 13th International symposium on loss prevention and safety promotion in the process industries, 6-9 June 2010, 365-372, Brugge, Belgium, ISBN-978-90-76019-291",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010 Characterization of large pool fires of organic compounds containing oxygen atoms"
    },
    {
      number: 6,
      event: "40th International annual conference of ICT, 88, 1-14, Fraunhofer Institute of Chemical Technology, Pfinztal (Berghausen), Germany, ISSN-0722-4087",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Numerical prediction of safety distances from large pool fires of organic peroxides"
    },
    {
      number: 5,
      event: "Proceedings of 36th National conference on fluid mechanics and fluid power - NCFMFP-2009 (NCFMFP-2009), Paper No. 58-CFD-5, 17-19 December 2009, COE Pune, India",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Measurement and CFD prediction of gas velocities and temperatures in large pool fires of organic peroxides"
    },
    {
      number: 4,
      event: "Proceedings of 7th International Conference on CFD in the minerals and process industries, 9-11 December 2009, CSIRO, Melbourne, ISBN-978-0-643-09825-1",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Prediction of burning rate of an accidentally released flammable fuel by means of CFD simulation"
    },
    {
      number: 3,
      event: "Proceedings of 6th U. S. National Combustion meeting, 17-20 May 2009, Ann Arbor, MI, USA",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Impact of fuel properties on the large pool fire (hydrocarbons and organic peroxides) characteristics"
    },
    {
      number: 2,
      event: "Proceedings of 4th European Combustion Meeting, 14-17 April, 2009, Vienna, Austria",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Radiative characteristics of large pool fires of organic peroxides"
    },
    {
      number: 1,
      event: "Proceedings of 35th National Conference on Fluid Mechanics and Fluid Power (NCFFMFP-08), 643-650, 11-13 December 2008, Bangalore, India",
      details: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2008, On the dynamics of organic peroxide pool flames"
    },
  ];
  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  
  return (
   <>
    <Navbar/>
    <div className='banner banner6'>
        <div className='overlayStyle'>
          <h1>Conference Proceedings</h1>
          <p>This section provides a comprehensive list of national and internatinal conferences, seminars, and events where we delivered our research insights. Click on the numbered circles to know more.</p>
        </div>
      </div>

      <div className='list-patents'>
        {conferenceData.map((conference, index) => (
          <div
            className='list-patent'
            key={conference.number}
            onClick={() => toggleAccordion(index)}
          >
            <div className='acc-list'>
              <div>
                <div className='no'>
                  <h3 style={{ cursor: 'pointer' }}>{conference.number}</h3>
                </div>
              </div>
              <h2>{conference.event}</h2>
            </div>
            {openIndex === index && conference.details && (
              <div className='accordion-body'>
                {conference.details}
              </div>
            )}
          </div>
        ))}
      </div>
    <Footer/>
   </>
  )
}

export default Conferences
