import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import WWDbg4 from '../../Assets/bgImages/TrainingProgramsBG.jpeg'
function TrainingPrograms() {

    return (
        <>
            <Navbar />
            <div className='banner'
                style={{
                    backgroundImage: `url(${WWDbg4})`,
                    backgroundSize: 'cover',        // Ensures image covers the whole area
                    backgroundPosition: 'center',   // Keeps the focal point in the center
                    backgroundRepeat: 'no-repeat',  // Prevents image repetition
                }}>
                <div className='overlay'>

                    <h1>Training Programs</h1>

                </div>
            </div>
            <div className="w-full py-4 flex">
        <div className="flex flex-wrap gap-4 justify-center p-6 font-bold w-full">
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/trainingprograms/introductory-training', '_blank')}
          >
            Introductory Training (max. two day)
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/trainingprograms/short-term-training', '_blank')}
          >
            Short Term Training (max. five days)
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/trainingprograms/long-term-training', '_blank')}
          >
            Long Term Training (max. 1 months)
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/trainingprograms/project-based-training', '_blank')}
          >
            Project-Based Training (max. 12 months)
          </button>
        </div>
      </div>
            <Footer />
        </>
    )
}
export default TrainingPrograms;