import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import EmptyBG2 from '../Assets/bgImages/WWRBG2.jpeg'

function OurMandate() {

    return (
        <>
            <Navbar />
            <div className='banner' 
             style={{
                backgroundImage: `url(${EmptyBG2})`,
                backgroundSize: 'cover',
                backgroundAttachment: 'local',         
                backgroundPosition: 'center',     
                backgroundRepeat: 'no-repeat',    
                height: 'auto',                  
                width: 'full', 
            }}
            >
                <div className='overlay'>
                    <h1>Our Mandate</h1>
                </div>
            </div>
            <div className=" w-full py-4 flex justify-center"
           
            >
                <p className=" p-6 leading-relaxed font-bold">
                    According to a survey conducted by the Underwriters Laboratory, India has the second-highest number of deaths and injuries in the world, resulting from workplace-related accidents and illnesses. The country suffers a loss of at least 4% of its annual GDP to work-related accidents and injuries. Proactive research would help organizations make informed decisions to enhance safety in the workplace.
                    <br /><br />
                    Our mandate is clear and simple: we want to make India a technologically safer nation through research-driven knowledge sharing using state-of-the-art experimental and computational tools.
                </p>
            </div>
            <Footer />
        </>
    )
}
export default OurMandate;