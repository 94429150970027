import React, { useState } from 'react';
import { send } from 'emailjs-com';

const styles = {
  formContainer: {
    maxWidth: '600px',
    
    margin: ' auto',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    outline: 'none',
    transition: 'border 0.3s',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    // margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    outline: 'none',
    transition: 'border 0.3s',
    minHeight: '100px',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData to handle file upload
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('message', formData.message);
    formDataToSend.append('resume', formData.resume); // Attach the resume file

    const templateParams = {
      to_name: formData.name,
      to_email: formData.email,
      message: formData.message,
      resume:formData.resume
    };

    try {
      // Use EmailJS to send the form data along with the resume
      await send(
        'service_3c32e5g',  // Replace with your EmailJS service ID
        'template_24a8n5t', // Replace with your EmailJS template ID
        templateParams,
        'xcmmmdjXBkJH3_UGV'      // Replace with your EmailJS user ID
      );
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }

    // Reset form
    setFormData({ name: '', email: '', message: '', resume: null });
  };

  return (
    <div style={styles.formContainer} className=''>
      <h2 className='text-black font-bold'> Work With Us</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
          style={styles.input}
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
          style={styles.input}
        />
        <textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
          style={styles.textarea}
        />
        <label className='mt-1 text-red-900'>*Upload CV</label>
        <input
          type="file"
          name="resume"
          onChange={handleFileChange}
          required
          style={styles.input}
        />
        <button type="submit" style={styles.button}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
