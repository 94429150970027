import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import WWDbg1 from '../../Assets/bgImages/WWDbg1.jpeg'
function DomainExpertise() {
  return (
    <>
      <Navbar />
      <div className='banner'
        style={{
          backgroundImage: `url(${WWDbg1})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'local',
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          height: 'auto',
          width: 'full',
        }}>
        <div className='overlay'>
          <h1>Domain Expertise</h1>
        </div>
      </div>
      <div className="w-full py-4 flex">
        <div className="flex flex-wrap gap-4 justify-center p-6 font-bold w-full">
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/domainexpertise/fire-explosion-safety', '_blank')}
          >
            Fire and Explosion Safety
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/domainexpertise/alternative-fuels', '_blank')}
          >
            Alternative Fuels, Combustion and Emission Control
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/domainexpertise/risk-assessment', '_blank')}
          >
            Risk Assessment of Process, Plants and Infrastructure
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/domainexpertise/cfd-modeling', '_blank')}
          >
            CFD Modeling
          </button>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default DomainExpertise;