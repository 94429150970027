import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import PSbg2 from '../../Assets/bgImages/PSbg2.jpeg'
function SoftwaresValidatedModels() {
  return (
    <>
      <Navbar />
      <div className='banner'
        style={{
          backgroundImage: `url(${PSbg2})`,
          backgroundSize: 'cover',        // Ensures image covers the whole area
          backgroundPosition: 'center',   // Keeps the focal point in the center
          backgroundRepeat: 'no-repeat',  // Prevents image repetition
        }}>
        <div className='overlay'>

          <h1>Softwares/Validated Models </h1>

        </div>
      </div>
      <div className="w-full py-4 flex">
        <div className="flex flex-wrap gap-4 justify-center p-6 font-bold w-full">
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/softwaresmodels/pool-fires', '_blank')}
          >
            Pool Fires, Jet Fires, and Fireballs
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/softwaresmodels/bleve-damage-assessment', '_blank')}
          >
            BLEVE and Damage Assessment
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/softwaresmodels/flammable-toxic-dispersion', '_blank')}
          >
            Flammable and Toxic Dispersion
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/softwaresmodels/building-fire-smoke-management', '_blank')}
          >
            Building Fire and Smoke Management
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/softwaresmodels/human-behaviour-evacuation', '_blank')}
          >
            Human Behaviour and Evacuation Modelling
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/softwaresmodels/alternative-fuel-combustion', '_blank')}
          >
            Alternative Fuel Combustion in Burners
          </button>
          <button
            className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
            onClick={() => window.open('/softwaresmodels/emission-predictions', '_blank')}
          >
            Emission Predictions using Detailed Chemistry
          </button>
        </div>

      </div>


      <Footer />
    </>
  )
}
export default SoftwaresValidatedModels;