import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ContactForm from "../Components/ContactForm";
import teamWorkImage2 from "../Assets/images/teamWork2.jpeg";
import teamWorkImage1 from "../Assets/images/teamWork1.jpeg";

const WorkWithUs3 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Navbar />

      <div
        className={`${isModalOpen ? "pointer-events-none" : ""}`}
        style={{ backgroundColor: "#FAF7F0" }}
      >
        {/* Hero Section with background image and text */}
        <div
          className="relative flex items-center justify-center text-center rounded-lg shadow-lg"
          style={{
            backgroundImage: `url(${teamWorkImage1}), url(${teamWorkImage2})`, // First image is teamWorkImage1, second is teamWorkImage2 both will take 50% of size. it's a hybrid what i found.
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundSize: "50% , 50% ", 
            backgroundPosition: "left, right",
            height: window.innerWidth <= 768 ? "380px" : "500px",
          }}
        >
          {/* Dark overlay for better text visibility */}
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="relative text-white">
            <h1 className="text-4xl font-bold font-poppins transition duration-500 ease-in-out">
              Career at TRAG
            </h1>
            <p className="mt-10 mx-5 text-lg font-poppins transition duration-500 ease-in-out">
              At TRAG, our expertise is at the heart of everything we do. We
              take pride in our commitment to excellence and our unwavering
              dedication to delivering high-quality solutions to practical
              problems. Join our team and be part of something great.
            </p>
          </div>
        </div>

        {/* ended banner */}
      </div>

      <div
        className={` ${isModalOpen ? "pointer-events-none" : ""}`}
        style={{ backgroundColor: "#FAF7F0" }}
      >
        <div className="container mx-auto py-8 ">
          {/* Hero Section */}
          {/* <div className="flex flex-col lg:flex-row items-center justify-between"> */}
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-3xl lg:text-5xl font-bold text-gray-800 mb-6">
              Work With US!!
            </h1>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md"
              onClick={openModal} // Open modal on button click
            >
              Apply Now
            </button>
            {/* </div> */}
          </div>
          <div
            className="rounded-lg mt-8 mb-8 flex justify-center items-center"
            style={{ backgroundColor: "#FFF7D1" }}
          >
            <h1 className="text-lg mx-4 mt-2 text-center lg:text-left max-w-full">
              We always look forward to receiving applications from the research
              enthusiasts for internship/BTech/MTech/MS/PhD/Post-Doc projects.
              If you are curious to work on cutting-edge technologies and the
              advancement of research for societal benefits, then please fill
              out the form and wait for our response.
            </h1>
          </div>

          {/* Cards Section */}
          {/* <div className="mb-5 flex flex-wrap gap-5 justify-center items-center">
            <Card obj={{ image: e1, title: "Large Pool Fire " }} />
            <Card obj={{ image: e2, title: "Large Pool Fire " }} />
            <Card obj={{ image: e3, title: "Large Pool Fire " }} />
            <Card obj={{ image: e4, title: "Large Pool Fire " }} />
          </div> */}
        </div>
      </div>

      {/* Modal Component */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center mt-10 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full relative mt-4  ">
            <button
              className="absolute top-4 right-4 text-black bg-gray-200 rounded-full p-2"
              onClick={closeModal} // Close modal on button click
            >
              &times;
            </button>
            <ContactForm></ContactForm> {/* ContactForm Component */}
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default WorkWithUs3;
