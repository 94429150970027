import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function ThermalRadiationJetFires() {
  return (
    <>
    <Navbar/>
    <div>thermal-radiation-jet-fires</div>
    </>
  )
}
