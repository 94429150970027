import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/kbfinal.png'
import teamsBG from '../Assets/bgImages/teamsBG.jpeg'

function Teams() {
  return (
    <>
      <Navbar />
      <h2 className="text-3xl text-center font-bold text-gray-800">Group Leader</h2>
      <div className="mt-8"
        style={{
          backgroundImage: `url(${teamsBG})`,   // Background image
          backgroundSize: 'cover',
          backgroundAttachment: 'local',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: 'auto',
          width: 'full',
        }}>
        <div className="w-full p-4">
          <h2 className="text-2xl font-bold text-white">Dr. Kirti Bhushan Mishra</h2>
          <div className="mt-4">
            {/* Image floating to the left with margin */}
            <img
              src={img}
              alt="Dr. Kirti Bhushan Mishra"
              className="float-left w-full md:w-1/3 mr-4 mb-4"
            />
            <p className="text-white leading-relaxed">
              Dr. Kirti Bhushan Mishra is an Assistant Professor in the Department of Mechanical and Industrial Engineering at the Indian Institute of Technology (IIT) Roorkee. He is also the founder of the Technological Risk Research and Analysis Group (TRAG) and in charge of the Heat Transfer Lab at IIT-R.
            </p>
            <p className="mt-4 text-white leading-relaxed">
              Dr. Mishra obtained his B.E. from RGPV, Bhopal, in 2001 and his M.Tech. from MANIT, Bhopal, in 2003, both with distinctions. After completing his PhD on fire safety and combustion from the University of Duisburg-Essen (UDE), Germany, in 2010, he continued his research journey and worked as a Senior Scientist (postdoc) at the BAM Federal Institute for Materials Research and Testing in Berlin, Germany, for over five years, where he extended his PhD work and devised new methods, test setups, and computational models to build safer technologies and reduce emissions. Since July 2015, he has been contributing as a faculty member of the Mechanical and Industrial Engineering Department at IIT Roorkee.
            </p>
            <p className="mt-4 text-white leading-relaxed">
              Having 34 articles, 21 patents, and 48 conference papers to his credit, Dr. Mishra has been one of the resource persons on fire safety and combustion in the country. He has been managing projects worth crores funded by the DRDO, SERB, and private organizations. Apart from supervising four PhD students, Prof. Mishra was instrumental in spreading awareness on research-driven knowledge on fire safety across India and abroad and was also an invited speaker in many national and international conferences and workshops. Dr. Mishra was also awarded the Early Career Research Grant in 2016 by the Science and Engineering Research Board, Govt. of India.
              His areas of interest include Combustion, Alternative Fuels, Fire, Emission Control, Explosion, Process and Plant Safety, Disaster prevention, and Applied Computational Engineering.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Teams
