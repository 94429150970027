// ProjectDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';

const projects = [
  {
    id: 1,
    title: 'Development of multi-layered firefighter’s suit for protection against thermal hazards and pressurized steam',
    agency: 'Ministry of Textiles, Govt. of India',
    role: 'Co-PI Lab Partner',
    startDate: '2022',
    endDate: '2024',
    status: 'Ongoing',
    url: 'https://www.trag.org.in/'

  },
  {
    id: 2,
    title: 'CFD modeling of dust dispersion and explosion in a pet coke dome',
    agency: 'Tata Projects Limited, Mumbai',
    role: 'PI',
    startDate: '2022',
    endDate: '',
    status: 'Completed',
    url: 'https://www.trag.org.in/'

  },
  {
    id: 3,
    title: 'Modelling of tunnel fires',
    agency: 'Fox Engineering, Pune',
    role: 'PI',
    startDate: '01/03/2020',
    endDate: '01/07/2020',
    status: 'Completed',
    url: 'https://www.trag.org.in/'
  },
  {
    id: 4,
    title: 'UL-94 Testing',
    agency: 'IOCL Panipat',
    role: 'PI',
    startDate: '01/01/2020',
    endDate: '01/04/2021',
    status: 'Completed',
    url: 'https://www.trag.org.in/'
  }
];

function ConsultancyProjectDetails() {
  const { id } = useParams();
  const project = projects.find((p) => p.id === parseInt(id));

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <>
    <Navbar/>
    <div className="container mt-5">
      <h2>{project.title}</h2>
    </div>
    {/* <Footer/> */}
      </>
  );
}

export default ConsultancyProjectDetails;
