import React, { useState } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import t1 from '../../Assets/Ankit_.jpg'
import t3 from '../../Assets/currentTeam/samartha.jpg'
import t4 from '../../Assets/currentTeam/Anjali.jpg'
import t5 from '../../Assets/currentTeam/mansi.jpg'
import t7 from '../../Assets/WhatsApp Image 2024-10-08 at 12.07.40 PM.jpeg'
import t8 from '../../Assets/RKU PIC.jpg'
import t9 from '../../Assets/Karishma_Yadav.jpg'

import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import arrow from '../../Assets/1549612.png'

import CurrentTeamBG from '../../Assets/bgImages/CurrentTeamBG.jpeg'
export default function CurrentWorkingTeam() {

    const togglemodal = (member) => {
        setSelectedMember(member);
        setmodal(!modal);
      };
    const [modal, setmodal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    const teamMembers = [
        {
            id: 1,
            name: 'Rajeeb Kumar Upadhyay',
            img: t8,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Mr. Rajeeb Kumar Upadhyay has done his B-Tech in Production engineering from BIT Sindri, Dhanbad. After this he moved to IIT(ISM) Dhanbad to pursue M-Tech in Mechanical Engineering with specialization in thermal engineering. Currently he is working as a full-time research scholar with the team. His research interests include combustion, emission analysis and its control in the internal combustion engines, alternative fuels etc

                </p>
            )
        },
        {
            id: 2,
            name: 'Pushpendra K Vishwakarma',
            img: t7,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Since December 2017, Pushpendra K Vishwakarma has been associated with the TRAG Lab at IIT Roorkee. He obtained his PhD in fire dynamics, presenting a thesis entitled “Experimental and numerical study on the effect of fuel and air rotation on fire whirl characteristics.” He was intricately involved in various experimental and numerical projects, contributing to drafting proposals, academic publications, and technical reports. He also participated in group activities involving establishing SFPE India Chapter, workshops and courses on Fire Modeling.
                </p>
            )
        },
        {
            id: 3,
            name: 'Samarth Jain',
            img: t3,
            description: (
                <>
                    <p className="text-gray-700 leading-relaxed">
                        Samarth Jain is a B.Tech student at Dayalbagh Educational Institute
                        (Deemed University), Agra, currently interning with the Technological
                        Risk Research and Analysis Group at IIT Roorkee. His work involves
                        numerical investigations of CH₄-H₂-air combustion in jet engine
                        combustors and developing machine learning algorithms for forest fire
                        detection in dense smoke, contributing valuable insights to ongoing
                        research projects and presenting his findings at international
                        conferences.
                    </p>
                </>
            )
        },
        {
            id: 4,
            name: 'Anjali Pandey',
            img: t4,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Anjali Pandey is a Second Year Mechanical Engineering student at College of Engineering Pune.
                    She is currently involved with TRAG as a research intern and working on the topic of combustion and fire safety

                </p>
            )
        },
        {
            id: 5,
            name: 'Mansi Sharma',
            img: t5,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Mansi Sharma is a BE Aerospace student at Chandigarh University, Mohali
                    Punjab, currently interning with the Technological Risk Research and
                    Analysis Group at IIT Roorkee.
                </p>
            )
        },
        {
            id: 6,
            name: 'Karishma Yadav',
            img: t9,
            description: (
                <p className="text-gray-700 leading-relaxed">
                   I have completed my Bachelors in Aeronautical Engineering from Aeronautical Society of India and Masters in Climate Science and Technology from IIT Bhubaneswar. I gained expertise in image processing while carrying out remote sensing analysis for satellites. Later, I joined DRDL, where I was involved in the analysis of external aerodynamics problems using CFD. I have done aero-characterization for different aerospace vehicles, in addition to working on separation dynamics studies for various missile configurations. I am going to pursue optimisation of routine fire detectors in aero-engines in IIT Roorkee. 
                </p>
            )
        }
    ];
    return (
        <>
            <Navbar />
            <div className="mt-8">
                <h2 className='mt-4' style={{ textAlign: 'center' }}>Our Current Working Team</h2>
                <div className='all-teams grid grid-cols-1 md:grid-cols-3 gap-6'
                    style={{
                        backgroundImage: `url(${CurrentTeamBG})`,   // Background image
                        backgroundSize: 'cover',
                        backgroundAttachment: 'local',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: 'auto',
                        width: 'full',
                    }}>
                    {teamMembers.map((member) => (
                        <div
                            className='team-card cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg p-4 hover:shadow-xl transition-shadow duration-300'
                            key={member.id}
                        >
                            <div className='w-full h-48 flex justify-center items-center bg-gray-100 rounded-lg'>
                                <img
                                    src={member.img}
                                    alt={member.name}
                                    className='max-h-full max-w-full object-contain rounded-lg'
                                />
                            </div>
                            <div className='team-name flex justify-between items-center w-full mt-4'>
                                <p className='text-lg font-semibold text-gray-800'>{member.name}</p>
                                <div onClick={() => togglemodal(member)}>
                                    <img className='arrow w-4 h-4' src={arrow} alt="arrow" />
                                </div>
                            </div>
                        </div>
                    ))}

                    {selectedMember && (
                        <Modal size='lg' isOpen={modal} toggle={() => togglemodal(null)}>
                            <ModalHeader toggle={() => togglemodal(null)}>
                                {selectedMember.name}
                            </ModalHeader>
                            <ModalBody>
                                <div className='modal-div flex space-x-6'>
                                    <div className='modal-left w-1/3'>
                                        <div className='w-full h-48 flex justify-center items-center bg-gray-100 rounded-lg'>
                                            <img
                                                src={selectedMember.img}
                                                alt={selectedMember.name}
                                                className='max-h-full max-w-full object-contain rounded-lg'
                                            />
                                        </div>
                                    </div>
                                    <div className='modal-right w-2/3'>
                                        <p className='text-gray-700 leading-relaxed'>
                                            {selectedMember.description}
                                        </p>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    )}
                </div>
            </div>
            <Footer />
        </>
    )
}
