import React from 'react';

const CarouselCard = ({ imageSrc, title, description }) => {
  return (
    <div className="carousel-card flex flex-col items-center bg-white p-6 rounded-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-3">
      {/* Image with border and subtle shadow */}
      <img
        src={imageSrc}
        alt={title}
        className="w-full h-64 object-cover border-4 border-gray-100 shadow-md rounded-t-lg"
      />
      <h3 className="mt-4 text-xl font-bold text-gray-800">{title}</h3>
      <p className="text-sm text-gray-600 mt-2 text-center hover:">{description}</p>
    </div>
  );
};

export default CarouselCard;