import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import WWDbg4 from '../../Assets/bgImages/WWDbg4.jpeg'
function ThirdPartyReview() {

    return (
        <>
            <Navbar />
            <div className='banner'
                style={{
                    backgroundImage: `url(${WWDbg4})`,
                    backgroundSize: 'cover',        // Ensures image covers the whole area
                    backgroundPosition: 'center',   // Keeps the focal point in the center
                    backgroundRepeat: 'no-repeat',  // Prevents image repetition
                }}>
                <div className='overlay'>

                    <h1>Third Party Review</h1>

                </div>
            </div>
            <div className="w-full py-4 flex">
                <div className="flex flex-wrap gap-4 justify-center p-6 leading-relaxed font-bold">
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/thirdpartyreview/safety-risk-assessment-reports', '_blank')}
                    >
                        Safety and Risk Assessment Reports
                    </button>
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/thirdpartyreview/claims-verification-validation', '_blank')}
                    >
                        Claims Verification and Validation
                    </button>
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/thirdpartyreview/vulnerability-studies', '_blank')}
                    >
                        Vulnerability Studies
                    </button>
                </div>

            </div>
            <Footer />
        </>
    )
}
export default ThirdPartyReview;