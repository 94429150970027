import image1 from './Assets/Slider/1.jpg';
import image2 from './Assets/Slider/2.jpg';
import image3 from './Assets/Slider/3.png';
import image4 from './Assets/Slider/4.jpg';
import image5 from './Assets/Slider/5.png';
import image6 from './Assets/Slider/6.png';
import image7 from './Assets/Slider/7.png';
import image8 from './Assets/Slider/8.png';
import image9 from './Assets/Slider/9.jpg';
import image10 from './Assets/Slider/10.jpg';
import image11 from './Assets/Slider/11.jpg';
import image12 from './Assets/Slider/12.jpg';
import image13 from './Assets/Slider/13.jpg';
import image14 from './Assets/Slider/14.jpg';
import image15 from './Assets/Slider/15.jpg';
import image16 from './Assets/Slider/16.jpg';
import image17 from './Assets/Slider/17.png';
import image18 from './Assets/Slider/18.png';
import image19 from './Assets/Slider/19.png';
import image20 from './Assets/Slider/20.jpg';
import image21 from './Assets/Slider/21.jpg';
import image22 from './Assets/Slider/22.jpg';
import image23 from './Assets/Slider/23.jpg';
import image24 from './Assets/Slider/24.jpg';
import image25 from './Assets/Slider/25.jpg';
import image26 from './Assets/Slider/26.jpg';
import image27 from './Assets/Slider/27.jpg';
import image28 from './Assets/Slider/28.jpg';
import image29 from './Assets/Slider/29.jpg';


const carouselData = [
    { "imageSrc": image1 },
    { "imageSrc": image2 },
    { "imageSrc": image3 },
    { "imageSrc": image4 },
    { "imageSrc": image5 },
    { "imageSrc": image6 },
    { "imageSrc": image7 },
    { "imageSrc": image8 },
    { "imageSrc": image9 },
    { "imageSrc": image10 },
    { "imageSrc": image11 },
    { "imageSrc": image12 },
    { "imageSrc": image13 },
    { "imageSrc": image14 },
    { "imageSrc": image15 },
    { "imageSrc": image16 },
    { "imageSrc": image17 },
    { "imageSrc": image18 },
    { "imageSrc": image19 },
    { "imageSrc": image20 },
    { "imageSrc": image21 },
    { "imageSrc": image22 },
    { "imageSrc": image23 },
    { "imageSrc": image24 },
    { "imageSrc": image25 },
    { "imageSrc": image26 },
    { "imageSrc": image27 },
    { "imageSrc": image28 },
    { "imageSrc": image29 },
];

export default carouselData;
