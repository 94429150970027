import React, { useState } from 'react';

function VideoComponent() {
  const [playing, setPlaying] = useState(false);

  const handleVideoClick = () => {
    setPlaying(!playing); // Toggle play/pause on click
  };

  return (
<div className="relative w-full border-2 max-w-2xl mx-auto rounded-lg overflow-hidden">
  <iframe
    src="https://drive.google.com/file/d/1gooW8K3DkawSE48FZrQrhVUoqZCQuHSW/preview"
    className="w-full h-72 sm:h-34 md:h-56 lg:h-80"
    allow="autoplay; encrypted-media"
    frameBorder="0"
    allowFullScreen
  ></iframe>
</div>

  );
}

export default VideoComponent;
