import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import img from "../Assets/standard_1.png";

function ProductsAndStandards() {
  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto p-6">
        {/* Title */}
        <h2 className="text-3xl font-bold text-center mb-8">Products and Standards</h2>

        {/* Our Products Section */}
        <div className="mb-12">
          <h3 className="text-2xl font-semibold mb-4">Test Benches for</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Burning Rate of flammable liquids and gases</li>
            <li>Fire retardant of composites for façades</li>
            <li>Emission measurements for burners and alternative fuels</li>
            <li>Flammability of alcohol-based formulations</li>
          </ul>
          <p className="mt-4">
            Our innovative test benches are designed to provide precise measurements and data for various applications in safety and environmental standards. Each bench is engineered for accuracy and reliability, ensuring the highest quality results for your testing needs.
          </p>
        </div>

        {/* Standards Development Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
          {/* Image */}
          <div className="text-center md:text-left">
            <img src={img} alt="Standard Development" className="w-full h-auto object-contain" />
          </div>

          {/* Content */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">1. Method and Apparatus for Burning Behavior Based Classification of Flammable Liquids (Under Review)</h3>
            <p className="mb-4">Flammable liquid storage tanks pose a serious fire and explosion threat to people, infrastructure, and the environment. Tank fire accidents are among the most frequently occurring industrial accidents. Depending on the quantity of combustibles and oxidizers, the fire can continue to burn for weeks or months. Several tank fire accidents have occurred in storage facilities across the globe [1-4]. In India, these incidents also continue to occur in various plants. One such incident happened in the Indian Oil Corporation Limited (IOCL), Jaipur plant in 2009, where the petrol tanks burned for over a week, resulting in a total loss of $1 billion and an irreparable environmental impact due to toxic gases and thick smoke.</p>
            <p>With numerous large-scale oil and gas storage and refineries planned to be constructed close to residential areas and within cities, the threat from accidental leaks of flammable materials is significant. The hazards posed by such fires can be accurately estimated through proper classification of flammable liquids stored in different tanks. The present standard proposes a new classification method aimed at ensuring fire safety and implementing preventive measures in existing and upcoming oil and gas storage plants and refineries across the country.</p>
          </div>
        </div>

        {/* Additional Standards Section */}
        <div className="mb-12">
          <h3 className="text-2xl font-semibold mb-4">2. Flammability Testing Standards</h3>
          <p className="mb-4">
            The standards for flammability testing are critical in determining the safe use of various materials in different environments. These tests help manufacturers ensure that their products meet safety regulations and can withstand exposure to fire and heat. Our flammability testing methods are aligned with international standards and are essential for industries such as construction, automotive, and textiles.
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>ASTM E84: Standard Test Method for Surface Burning Characteristics of Building Materials</li>
            <li>ISO 5660: Reaction to Fire Tests – Heat Release, Smoke Production, and Mass Loss</li>
            <li>UL 94: Standard for Tests for Flammability of Plastic Materials for Parts in Devices and Appliances</li>
          </ul>
          <p className="mt-4">
            Adhering to these standards not only promotes safety but also enhances consumer confidence in products, making it essential for manufacturers to conduct thorough flammability testing.
          </p>
        </div>

        {/* Products Overview Section */}
        <div className="mb-12">
          <h3 className="text-2xl font-semibold mb-4">Overview of Our Products</h3>
          <p className="mb-4">
            Our products are designed with a focus on safety, accuracy, and compliance with industry standards. We offer a range of testing equipment that caters to various applications in fire safety and environmental monitoring. Our product line includes:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Advanced Testing Benches</li>
            <li>Emission Monitoring Systems</li>
            <li>Flammability Testing Apparatus</li>
            <li>Customized Safety Solutions</li>
          </ul>
          <p className="mt-4">
            Each product is thoroughly tested and validated to ensure it meets rigorous standards, providing you with peace of mind in your operations.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProductsAndStandards;
