import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import EmptyBG1 from '../Assets/bgImages/WWRBG1.jpeg'
function OurOrigin() {
    
    return (
        <>
            <Navbar />
            <div className='banner'
             style={{
                backgroundImage: `url(${EmptyBG1})`,
                backgroundSize: 'cover',
                    backgroundAttachment: 'local',         
                    backgroundPosition: 'center',     
                    backgroundRepeat: 'no-repeat',    
                    height: 'auto',                  
                    width: 'full',  
            }}>
                <div className='overlay'>

                    <h1>Our Origin</h1>

                </div>
            </div>
            <div className=" w-full py-4 flex justify-center"
           
            >
                <p className=" p-6 leading-relaxed font-bold">
                We are a dedicated set of individuals who come from the world of science, engineering and technology. By  utilizing the tools which are well-validated and widely accepted in the developed nations we have developed ourselves as a trustworthy technology partner for many of our esteemed clients.
                </p>
            </div>
            <Footer />
        </>
    )
}
export default OurOrigin;