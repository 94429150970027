import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import PSbg3 from '../../Assets/bgImages/PSbg3.jpeg'
function DraftStandards() {
    return (
        <>
            <Navbar />
            <div className='banner'
                style={{
                    backgroundImage: `url(${PSbg3})`,
                    backgroundSize: 'cover',        // Ensures image covers the whole area
                    backgroundPosition: 'center',   // Keeps the focal point in the center
                    backgroundRepeat: 'no-repeat',  // Prevents image repetition
                }}>
                <div className='overlay'>

                    <h1>Draft Standards</h1>

                </div>
            </div>
            <div className="w-full py-4 flex justify-center">
                <div className="flex flex-col items-center p-6 leading-relaxed font-bold">
                    <button
                        className="mt-4 w-full py-3 px-6 text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/draftstandards/storageAndTransport', '_blank')}
                    >
                        Storage and Transportation of Flammable Liquids and Liquified Gases
                    </button>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default DraftStandards;