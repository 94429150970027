import React, { useEffect, useState } from "react";
import logo from "../Assets/logo_M-removebg.png";
import { Link } from "react-router-dom";

function Footer() {
  const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
    // Retrieve the stored visitor count from localStorage
    const visits = localStorage.getItem("visitorCount");

    // If visitorCount exists, increment it. Otherwise, start at 1.
    if (visits) {
      const newCount = parseInt(visits) + 1; // Increment the existing count
      localStorage.setItem("visitorCount", newCount);
      setVisitorCount(newCount);
    } else {
      localStorage.setItem("visitorCount", 1); // Start at 1 for the first visit
      setVisitorCount(1);
    }
  }, []); // Ensure this runs only once on mount

  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
        {/* Logo Section */}
        <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
          <img className="w-28 h-auto mb-4" src={logo} alt="Logo" />
          <p className="text-gray-400">Copyright © 2024 trag.org.in</p>
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col items-center md:items-start space-y-2">
          <h5 className="text-lg font-semibold text-gray-200 mb-4">Quick Links</h5>
          <Link className="text-gray-400 hover:text-white no-underline" to="/">Home</Link>
          <Link className="text-gray-400 hover:text-white no-underline" to="/domainexpertise">Domain Expertise</Link>
          <Link className="text-gray-400 hover:text-white no-underline" to="/ourmotivation">Our Motivation</Link>
          <Link className="text-gray-400 hover:text-white no-underline" to="/testbenches">Products & Standards</Link>
        </div>

        {/* More Links */}
        <div className="flex flex-col items-center md:items-start space-y-2">
          <h5 className="text-lg font-semibold text-gray-200 mb-4">More</h5>
          <Link className="text-gray-400 hover:text-white no-underline" to="/contact">Contact Us</Link>
          <Link className="text-gray-400 hover:text-white no-underline" to="/workwithus">Work With Us</Link>
          <Link className="text-gray-400 hover:text-white no-underline" to="/teams">Our Team</Link>
        </div>

        {/* Social Media Links */}
        <div className="flex flex-col items-center md:items-start space-y-4">
          <h5 className="text-lg font-semibold text-gray-200">Follow Us</h5>
          <div className="flex space-x-4">
            <Link to="https://www.linkedin.com/in/technological-risk-research-and-analysis-group-3568a5332?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" className="text-gray-400 hover:text-white">
              <i className="bi bi-linkedin text-2xl"></i>
            </Link>
            <Link to="https://x.com/TRAG_iitr" className="text-gray-400 hover:text-white">
              <i className="bi bi-twitter text-2xl"></i>
            </Link>
            <Link to="https://www.facebook.com/profile.php?id=61566830322555" className="text-gray-400 hover:text-white">
              <i className="bi bi-facebook text-2xl"></i>
            </Link>
            <Link to="https://www.instagram.com/trag_iitr?igsh=YnI5N281cnVjbGUw&utm_source=qr" className="text-gray-400 hover:text-white">
              <i className="bi bi-instagram text-2xl"></i>
            </Link>
            <Link to="https://www.youtube.com/@trag-technologicalriskrese9901" className="text-gray-400 hover:text-white">
              <i className="bi bi-youtube text-2xl"></i>
            </Link>
          </div>
        </div>
        <div>

        </div>
        {/* <div className="text-end mt-8">
          <p className="bg-red-600 text-white py-2 px-4 rounded-lg inline-block">
            Visitor Count: {visitorCount}
          </p>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
