import React, { useState } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'

import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import arrow from '../../Assets/1549612.png'

import t1 from '../../Assets/Ankit_.jpg'

import t6 from '../../Assets/image_123650291 (2).JPG'
import t7 from '../../Assets/WhatsApp Image 2024-10-08 at 12.07.40 PM.jpeg'
import t89 from '../../Assets/image0.jpeg'
import t99 from '../../Assets/Ayush_Gupta_IMG.jpg'
import t55 from '../../Assets/PHOTO.jpg'


import Alumni2 from '../../Assets/currentTeam/Saumitra.jpg'

import AluminiBG from '../../Assets/bgImages/AluminiBG.jpeg'

export default function OurAlumni() {

    const togglemodal = (member) => {
        setSelectedMember(member);
        setmodal(!modal);
    };
    const [modal, setmodal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    const teamAlumni = [
        {
            id: 1,
            name: 'Dr. Ankit Sharma',
            img: t1,
            description: (
                <>
                    <p className="mt-2 text-gray-700">
                        <strong>Bio:</strong> Dr. Ankit Sharma, a renowned fire scientist, has been making significant contributions to the field through his research at NIST and Johns Hopkins University
                    </p>
                    <p className="mt-2 text-gray-700">
                        With a Ph.D. from IIT Roorkee and experience at leading institutions, he has expertise in various areas of fire engineering, including material flammability, Li-ion battery fires, and microgravity combustion
                    </p>
                    <p className="mt-2 text-gray-700">
                        His research has been recognized with prestigious awards like the Global 5 Under 35 Award from SFPE.
                    </p>
                </>
            )
        },
        {
            id: 2,
            name: 'Dr. Saumitra Mishra',
            img: Alumni2,
            description: (
                <>
                    <p className="font-semibold">Since 2017, Saumitra Mishra has been associated with the TRAG Lab at IIT Roorkee. He obtained his PhD in combustion sciences, presenting a thesis titled:</p>
                    <blockquote className="italic my-2">
                        "Experimental and Numerical Investigation of Emission Characteristics from 20-70 kW Burner Flames."
                    </blockquote>
                    <p>
                        He was intricately involved in various experimental and numerical projects, contributing to drafting proposals, academic publications, and technical reports. Dr. Saumitra Mishra, currently working as a researcher in energy technology division at Chalmers University, Sweeden. He has been making significant contributions to sustainable cement production in collaboration with Heidelberg Materials Sweden. His research is focused on advancing sustainable cement production by developing measurement methodologies and instrumentation for diagnostics and control systems of an experimental furnace. His work includes generating high-quality experimental data and conducting trials using a CO₂ plasma generator to produce cement, contributing to improved energy efficiency and reduced carbon emissions.
                    </p>
                </>
            )
        },
        {
            id: 3,
            name: 'Guru Manoj',
            img: t6,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Guru Manoj S. was a Master's student (2021-2023) in the Department of Mechanical and Industrial Engineering at IIT Roorkee, specializing in Thermal Engineering. During his time at the TRAG Lab, he conducted experimental research related to Vehicular Emissions and Fire Dynamics. He completed his Bachelor's in Mechanical Engineering from PSG College of Technology, Coimbatore. Prior to his master's, he worked as a Mechanical Maintenance Engineer at Bharat Aluminium Company Limited (BALCO) from 2017 to 2019. He is currently working as a Manager (Team Member) in Advanced Engineering and Solution Development at Reliance New Solar Energy Limited, where he focuses on cutting-edge engineering solutions in the solar energy sector.
                </p>
            )
        },

        {
            id: 4,
            name: 'Mahesh Yadav Boyeni',
            img: t89,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Mahesh Yadav Boyeni is currently working as an Assistant Manager in the R&D department at Bajaj Auto Limited.
                    He graduated with a B.E. in Mechanical Engineering from Vasavi College of Engineering, Hyderabad in 2019, and later completed M.Tech in Thermal Engineering from IIT Roorkee in 2022.
                    As part of the TRAG, the primary goal is to explore biofuels and their blends with additives to reduce harmful emissions and potentially replace fossil fuels. In our research, we conducted experiments on a multi-cylinder diesel engine using Waste Cooking Oil (WCO) as a biofuel, combined with di-tert-butyl-peroxide (DTBP) and ethanol as additives in varying proportions. The aim was to find the optimum blend that minimizes emissions without compromising engine performance.
                    He also presented this work at the 6th International Conference on Sustainable Energy and Environmental Challenges (VI SEEC) in Lucknow in 2021.”

                </p>
            )
        },

        {
            id: 5,
            name: 'Dharmendra Kumar',
            img: t55,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Dharmendra Kumar (Mtech 2020 passout), Currently pursuing PhD from iit BHU
                    The TRAG Lab focuses on research into fire behavior, dynamics, and safety. Its work involves safety or detection technologies, especially for environments where fire hazards are prevalent, their research could offer helpful insights. Their findings are applicable in areas like Building fire,effluent measurement, thermal sensors, and risk assessment. It would be useful if you're dealing with safety equipment or working on designs that include fire-risk environments.
                </p>
            )
        },
        {
            id: 6,
            name: 'Ayush Guupta',
            img: t99,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Ayush Gupta is from Aligarh Muslim University.
                    As a member of TRAG, he worked as a Project Intern in the team contributing to the HAZOP Modeling project utilizing Machine Learning techniques. His role involved developing models to improve hazard identification and risk assessment, streamlining the process for greater efficiency and safety.
                </p>
            )
        },

        {
            id: 7,
            name: 'Dr. Pushpendra Vishwakarma',
            img: t7,
            description: (
                <p className="text-gray-700 leading-relaxed">
                    Since December 2017, Pushpendra K Vishwakarma has been associated with the TRAG Lab at IIT Roorkee. He obtained his PhD in fire dynamics, presenting a thesis entitled “Experimental and numerical study on the effect of fuel and air rotation on fire whirl characteristics.” He was intricately involved in various experimental and numerical projects, contributing to drafting proposals, academic publications, and technical reports. He also participated in group activities involving establishing SFPE India Chapter, workshops and courses on Fire Modeling.
                </p>
            )
        }
    ];
    return (
        <>
            <Navbar />
            <h2 className='mt-4' style={{ textAlign: 'center' }}>Our Alumni</h2>

            <div className='all-teams grid grid-cols-1 md:grid-cols-3 gap-6 relative'
                style={{
                    backgroundImage: `url(${AluminiBG})`,   // Background image
                    backgroundSize: 'cover',
                    backgroundAttachment: 'local',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: 'auto',
                    width: 'full',
                }}>
                <div className="absolute inset-0 bg-white opacity-50"></div>
                {teamAlumni.map((member) => (
                    <div
                        className='team-card cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg p-4 hover:shadow-xl transition-shadow duration-300'
                        key={member.id}
                    >
                        <div className='w-full h-48 flex justify-center items-center bg-gray-100 rounded-lg'>
                            <img
                                src={member.img}
                                alt={member.name}
                                className='max-h-full max-w-full object-contain rounded-lg'
                            />
                        </div>
                        <div className='team-name flex justify-between items-center w-full mt-4'>
                            <p className='text-lg font-semibold text-gray-800'>{member.name}</p>
                            <div onClick={() => togglemodal(member)}>
                                <img className='arrow w-4 h-4' src={arrow} alt="arrow" />
                            </div>
                        </div>
                    </div>
                ))}

                {selectedMember && (
                    <Modal size='lg' isOpen={modal} toggle={() => togglemodal(null)}>
                        <ModalHeader toggle={() => togglemodal(null)}>
                            {selectedMember.name}
                        </ModalHeader>
                        <ModalBody>
                            <div className='modal-div flex space-x-6'>
                                <div className='modal-left w-1/3'>
                                    <div className='w-full h-48 flex justify-center items-center bg-gray-100 rounded-lg'>
                                        <img
                                            src={selectedMember.img}
                                            alt={selectedMember.name}
                                            className='max-h-full max-w-full object-contain rounded-lg'
                                        />
                                    </div>
                                </div>
                                <div className='modal-right w-2/3'>
                                    <p className='text-gray-700 leading-relaxed'>
                                        {selectedMember.description}
                                    </p>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
            </div>
            <Footer />
        </>
    )
}
