import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import PSbg1 from '../../Assets/bgImages/PSbg1.jpeg'
function TestBenches() {
    return (
        <>
            <Navbar />
            <div className='banner'
                style={{
                    backgroundImage: `url(${PSbg1})`,
                    backgroundSize: 'cover',        // Ensures image covers the whole area
                    backgroundPosition: 'center',   // Keeps the focal point in the center
                    backgroundRepeat: 'no-repeat',  // Prevents image repetition
                }}>
                <div className='overlay'>

                    <h1>Test benches for the measurement of</h1>

                </div>
            </div>
            <div className="w-full py-4 flex">
                <div className="flex flex-wrap gap-4 justify-center p-6 font-bold w-full">
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/testbenches/burning-rate-flammable-liquids', '_blank')}
                    >
                        Burning Rate of Flammable Liquids and Gasses
                    </button>
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/testbenches/thermal-radiation-jet-fires', '_blank')}
                    >
                        Thermal Radiation from Subsonic Jet Fires
                    </button>
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/testbenches/fire-turbulence-interaction', '_blank')}
                    >
                        Fire - Turbulence Interaction
                    </button>
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/testbenches/fire-retardancy-composites', '_blank')}
                    >
                        Fire Retardancy of Composites for Façade Application
                    </button>
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/testbenches/emission-combustion-alternative-fuels', '_blank')}
                    >
                        Emission from Combustion of Alternative Fuels
                    </button>
                    <button
                        className="flex-grow py-3 px-6 min-w-[200px] text-center bg-zinc-600 text-white hover:bg-zinc-800 transition-colors duration-300 rounded-lg text-lg break-words"
                        onClick={() => window.open('/testbenches/flammability-alcohol-formulations', '_blank')}
                    >
                        Flammability of Alcohol-Based Formulations
                    </button>
                </div>

            </div>

            <Footer />
        </>
    )
}
export default TestBenches;