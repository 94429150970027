import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function FlammabilitySmokeToxicity() {
  return (
    <>
    <Navbar/>
    <div>Flammability-smoke-toxicity</div>
    </>
  )
}
