import React from 'react'
import Navbar from '../../../Components/Navbar'

export default function CombustionAlternativeFuels() {
  return (
<>
<Navbar/>
    <div>combustionAlternativeFuels</div>
</>
  )
}
